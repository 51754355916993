import React, { useState, useContext } from 'react'
import {fetchAuth} from '@components/shared/api';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '@context/AuthContext';
import InputField from '@components/shared/InputField';

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/auth', false, /\.(svg)$/));

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { handleClose, handleRegistration, handleReset, login } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleLoginSuccess = () => {
    login();
    handleClose();
    navigate('/');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetchAuth(email, password);
      if (response.data.status === false) {
        setError(response.data.msg);
      } else {
        localStorage.setItem('token', response.data.token);
        handleLoginSuccess();
        handleClose();
      }
    } catch (err) {
      console.error(err);
      setError(`Ошибка при авторизации: ${err}`);
    }
  };

  return (
    <div className="auth-form">
      <p className="text-blue-left">Войдите в аккаунт</p>
      <h2>МЫ РАДЫ ВАС ВИДЕТЬ СНОВА</h2>
      <form className='mt-4' onSubmit={handleSubmit}>
        <InputField
          label="Email"
          type="email"
          placeholder="Ваша почта"
          logo={images['login.svg']}
          value={email}
          handleChange={(e) => setEmail(e.target.value)}
        />
        <div>
          <InputField
            label="Пароль"
            type="password"
            placeholder="Введите пароль"
            logo={images['password.svg']}
            value={password}
            handleChange={(e) => setPassword(e.target.value)}
          />
          <div className='reset-password-btn' onClick={handleReset}>Забыли пароль?</div>
        </div>
        <button type="submit" className='mt-4'>Войти</button>
      </form>
      {error && <p className="error"><b>Ошибка:</b> {error}</p>}
      <p className='switch-auth-window'>Нет аккаунта? <div onClick={handleRegistration}>Регистрация</div></p>
    </div>
  );
}
