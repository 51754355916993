import React from 'react';
import './Advantages.css';

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/partners/becomePartners/advantages', false, /\.(svg)$/));

export default function Advantages() {
  const AdvantageCard = ({ image, label, description }) => {
    return (<div className='partnership-advantage-card'>
      <div className='image-block'>
        <HandySvg src={image} className='logo-20x20' />
      </div>
      <div className='card-label'>{label}</div>
      <div className='card-description'>{description}</div>
      <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" className='more-info-btn'>
        <span>Подробнее
          <HandySvg src={images['arrow.svg']} className='logo-15x15 currentColor' />
        </span>
      </a>
    </div>);
  }

  return (
    <div className='partnership-advantages'>
      <div className='home-chapter-box'>ПРЕИМУЩЕСТВА</div>
      <div className='label'>В чем партнерство с нами лучше от конкурентов</div>
      <div className='partnership-advantages-container'>
        <AdvantageCard
          image={images['timeFast.svg']}
          label='Быстрый вывод'
          description='Вывод средств доступен в любое время и в любой день. Сумма минимального вывода составляет $50.'
        />
        <AdvantageCard
          image={images['benefit.svg']}
          label='Отсутствие комиссии и сборов'
          description='Получайте 100% того, что заработали. В нашем сервисе нет прочих комиссии и сборов.'
        />
        <AdvantageCard
          image={images['sackDollar.svg']}
          label='Достойное вознаграждение'
          description='За каждый реферал вы получите 10% от суммы первого пополнения.'
        />
        <AdvantageCard
          image={images['coins.svg']}
          label='Фиксированные бонусы за рефералов'
          description='Помимо процентного вознаграждения мы предлагаем фиксированную оплату за каждые 100 рефералов.'
        />
        <AdvantageCard
          image={images['gift.svg']}
          label='Подарки лучшим'
          description='Мы не оставляем без внимания самых лучших партнеров. Конкурируйте и попадите в десятку лучших и выигрывайте топовые подарки.'
        />
      </div>
    </div>
  )
}
