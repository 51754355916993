import React, { useEffect, useState, useContext } from 'react';
import {getPartner} from '@components/shared/api';
import { AuthContext } from '@context/AuthContext';
import Header from '@components/Header/Header';
import BecomePartners from './BecomePartners';
import PartnerInfo from './PartnerInfo';
import Footer from '@components/Footer/Footer';
import './Partnership.css';

export default function Partnership() {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [data, setData] = useState(null); // Данные партнера
  const [loading, setLoading] = useState(true); // Состояние загрузки
  const [isPartner, setIsPartner] = useState(false); // Состояние для проверки партнерства

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = getPartner();
        if (response.data) {
          if (response.data.status) {
            setData(response.data);
            setIsPartner(true);
          } else {
            if (response.data.message === "You dont partner") {
              setIsPartner(false);
            } else {
              logout();
            }
          }
        }
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, logout]);

  // Отображение экрана загрузки
  if (loading) {
    return (
      <div className="loading-screen">
        <p>Загрузка данных...</p>
      </div>
    );
  }

  return (
    <>
      <Header />
      {isAuthenticated && isPartner ? <PartnerInfo data={data} /> : <BecomePartners />}
      <Footer />
    </>
  );
}
