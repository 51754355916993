import React from 'react';
import './Service.css';

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/about/service', false, /\.(svg)$/));

export default function Service() {
  const AdvantageBlock = ({ image, label }) => (
    <div className='service-advantage-block'>
      <HandySvg src={image} className='logo-20x20' />
      <span className='label'>{label}</span>
    </div>
  );

  const AdvantageCard = ({ image, label, description }) => {
    return (<div className='partnership-advantage-card'>
      <div className='image-block'>
        <HandySvg src={image} className='logo-20x20' />
      </div>
      <div className='card-label'>{label}</div>
      <div className='card-description'>{description}</div>
      <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" className='more-info-btn'>
        <span>Подробнее</span>
        <HandySvg src={images['arrow.svg']} className='logo-15x15 currentColor' />
      </a>
    </div>);
  }

  return (
    <>
      <div className='about-service-cards'>
        <div className='services-advantages-container'>
          <AdvantageBlock
            image={images['analyse.svg']}
            label='Аналитика вашего канала'
          />
          <AdvantageBlock
            image={images['bolt.svg']}
            label='Быстрый рост аудитории'
          />
          <AdvantageBlock
            image={images['user-headset.svg']}
            label='Личная поддержка 24/7'
          />
          <AdvantageBlock
            image={images['bonus.svg']}
            label='Бонусы и розыгрыши'
          />
        </div>
      </div>
      <div className='about-service-1'>
        <div className='label-chapter-box' style={{ marginTop: '65px' }}>НАШИ УСЛУГИ</div>
        <div className='label'>Узнайте подробнее о наших услугах</div>
        <div className='description'>Мы предлагаем надежную накрутку подписчиков, просмотров и реакций, чтобы ваш канал стал еще более популярнее и востребованнее.</div>
        <div className='partnership-advantages-container'>
          <AdvantageCard
            image={images['note.svg']}
            label='Создание задачи и управление'
            description='Значимость этих проблем настолько очевидна, что консультация с широким активом требуют от нас анализа дальнейших направлений развития.'
          />
          <AdvantageCard
            image={images['message-bot.svg']}
            label='Выгодное вливание трафика'
            description='Значимость этих проблем настолько очевидна, что консультация с широким активом требуют от нас анализа дальнейших направлений развития.'
          />
          <AdvantageCard
            image={images['tachometr.svg']}
            label='Быстрая скорость сервиса'
            description='Значимость этих проблем настолько очевидна, что консультация с широким активом требуют от нас анализа дальнейших направлений развития.'
          />
          <AdvantageCard
            image={images['bonus.svg']}
            label='Постоянные розыгрыши и бонусы'
            description='Значимость этих проблем настолько очевидна, что консультация с широким активом требуют от нас анализа дальнейших направлений развития.'
          />
          <AdvantageCard
            image={images['benefit.svg']}
            label='Скидки постоянным пользователям'
            description='Значимость этих проблем настолько очевидна, что консультация с широким активом требуют от нас анализа дальнейших направлений развития.'
          />
          <AdvantageCard
            image={images['earnings.svg']}
            label=''
            description='Значимость этих проблем настолько очевидна, что консультация с широким активом требуют от нас анализа дальнейших направлений развития.'
          />
        </div>
      </div>
    </>
  )
}
