import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('');

  // Функция для получения темы из localStorage или по умолчанию из настроек браузера
  const fetchTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    } else {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const userPrefersDark = mediaQuery.matches;
      setTheme(userPrefersDark ? 'dark' : 'light');
    }
  };

  useEffect(() => {
    fetchTheme();
  }, []);

  // Сохраняем тему в localStorage при изменении
  useEffect(() => {
    if (theme !== '') {
      localStorage.setItem('theme', theme);
      document.body.setAttribute('data-theme', theme);
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
