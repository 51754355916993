import React, { useContext } from 'react'
import { AuthContext } from '@context/AuthContext';
import './HowItWork.css'

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/partners/becomePartners/howItWork', false, /\.(svg)$/));

export default function HowItWork() {
  const { isAuthenticated, handleRegistration, handleLogin } = useContext(AuthContext);

  const handleContactUs = () => {
    window.open('https://t.me/toupSupport_bot?start=partner', '_blank');
  };

  const HowItWorkBlock = ({ isReverse, stepNumber, label, description, buttons, image }) => (
    <div className={`partner-step ${isReverse ? 'reverse' : ''}`}>
      <div className='circle-45x45'>
        <span className='step-counter'>{stepNumber}</span>
      </div>
      <div className='partner-step-specification'>
        <div className='label'>{label}</div>
        <div className='description'>{description}</div>
        {buttons}
      </div>
      <div className='partner-step-image'>
        <HandySvg src={image} />
      </div>
    </div>
  )

  return (
    <div className='partnership-how-it-work'>
      <div className='home-chapter-box'>КАК ЭТО РАБОТАЕТ?</div>
      <div className='label'>Узнайте каждый шаг партнерства с нами</div>
      <div className='steps-container'>
        <HowItWorkBlock
          isReverse={false}
          stepNumber={1}
          label='Войдите в свой аккаунт'
          description='Заполните все поля регистрации и войдите в свой аккаунт.'
          buttons={<button className='create-account-btn' onClick={isAuthenticated ? handleContactUs : handleRegistration}>Создать аккаунт</button>}
          image={images['profile.svg']}
        />
        <HowItWorkBlock
          isReverse={true}
          stepNumber={2}
          label='Откройте вкладку “Стать партнером”'
          description='Скопируйте свой реферальный код и отправьте друзьям. Они получат 5% от первого пополнения после ввода вашего кода.'
          buttons={<button className='create-account-btn' onClick={isAuthenticated ? handleContactUs : handleLogin}>Стать партнером</button>}
          image={images['profile.svg']}
        />
        <HowItWorkBlock
          isReverse={false}
          stepNumber={3}
          label='Получайте вознаграждения'
          description='Вы получите 10% от первого пополнения и стабильные бонусы за каждые 100 рефералов. Вывести можно по реквизитам карты или личного счета.'
          buttons={<button className='create-account-btn' onClick={isAuthenticated ? handleContactUs : handleLogin}>Начать сейчас</button>}
          image={images['profile.svg']}
        />
      </div>
    </div>
  )
}
