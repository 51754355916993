import React, { useState } from 'react'

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/support', false, /\.(svg)$/));

export default function SupportDialogsMenu({ activeDialog, handleActiveDialog, listDialogs }) {
  const getDialogTime = (time) => {
    const secPerMinute = 60;
    const secPerHour = 60 * secPerMinute;
    const secPerDay = 24 * secPerHour;
    const secPerWeek = 7 * secPerDay;
    const secPerMonths = 30.42 * secPerDay;
    const secPerYear = 365 * secPerDay;

    const years = Math.floor(time / secPerYear);
    const months = Math.floor(time / secPerMonths);
    const weeks = Math.floor(time / secPerWeek);
    const days = Math.floor((time % secPerWeek) / secPerDay);
    const hours = Math.floor((time % secPerDay) / secPerHour);
    const minutes = Math.floor((time % secPerHour) / secPerMinute);

    if (years > 0) {
      return `${years} ${years === 1 ? 'год' : 'года'}`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? 'месяц' : 'месяца'}`;
    } else if (weeks > 0) {
      return `${weeks} ${weeks === 1 ? 'неделю' : 'недели'}`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? 'день' : 'дня'}`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? 'час' : 'часов'}`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? 'минуту' : 'минут'}`;
    } else {
      return "1 минуту";
    }
  };

  const DialogItem = ({ dialogItem, index }) => {
    const now = Date.now(); // Текущее время в миллисекундах
    const timeDifference = Math.floor((now - new Date(dialogItem.date_create * 1000).getTime()) / 1000); // Разница в секундах

    return (
      <div
        className={`dialog-item${index === activeDialog ? ' active' : ''}`}
        onClick={() => handleActiveDialog(index)}
      >
        <div className='support-dialog-logo'>
          <HandySvg src={images['support.svg']} />
        </div>
        <div style={{ width: '85%' }}>
          <div className='dialog-info'>
            <div className='label'>Агент поддержки</div>
            <div className='time'>{`${getDialogTime(timeDifference)} назад`}</div>
          </div>
          <div className='message'>{dialogItem.title}</div>
        </div>
      </div>
    )
  }

  return (
    <div className='support-dialogs'>
      {listDialogs.map((dialogItem, index) => (
        <DialogItem dialogItem={dialogItem} index={index} />
      ))}
    </div>
  )
}
