import React from 'react'
import './News.css';

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/about/news', false, /\.(svg)$/));
const pngImages = ImageUtils.importAllImages(require.context('@assets/about/news/png', false, /\.(png)$/));

export default function News() {
  const NewsCard = ({ image, type, label, description, link }) => (
    <div className='news-card'>
      <img src={image} alt={'image1234'}/>
      <div className='news-type'>{type}</div>
      <div className='label'>{label}</div>
      <div className='description'>{description}</div>
      <a href={link}>
        <span style={{ color: '#2D8EFF', textDecoration: 'none' }}>Открыть статью
          <HandySvg src={images['arrow.svg']} className='logo-15x15 currentColor' />
        </span>
      </a>
    </div>
  )

  return (
    <div className='about-news-background'>
      <div className='about-news'>
        <div className='label-chapter-box'>НОВОСТИ</div>
        <div className='label'>Наши последние обновления</div>
        <div className='news-container'>
          <NewsCard
            image={pngImages['iphone.png']}
            type='Статья'
            label='Мы обновили сайт и добавили новые функции'
            description='Значимость этих проблем настолько очевидна, что консультация с широким активом требуют от нас анализа дальнейших направлений развития.'
            link='#'
          />
          <NewsCard
            image={pngImages['iphone.png']}
            type='Статья'
            label='Мы обновили сайт и добавили новые функции'
            description='Значимость этих проблем настолько очевидна, что консультация с широким активом требуют от нас анализа дальнейших направлений развития.'
            link='#'
          />
          <NewsCard
            image={pngImages['iphone.png']}
            type='Статья'
            label='Мы обновили сайт и добавили новые функции'
            description='Значимость этих проблем настолько очевидна, что консультация с широким активом требуют от нас анализа дальнейших направлений развития.'
            link='#'
          />
        </div>
      </div>
    </div>
  )
}
