import React, { useContext } from 'react'
import { AuthContext } from '@context/AuthContext';
import { Link, useLocation } from 'react-router-dom';

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/profile/options', false, /\.(svg)$/));


export default function OptionsSidebar() {
  const { isAdmin, logout } = useContext(AuthContext);
  const location = useLocation();

  const getLinkClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const LinkItem = ({ logo, label, link }) => (
    <Link to={link} className={`profile-settings-link ${getLinkClass(link)}`}>
      <HandySvg src={images[`${logo}.svg`]} className="logo-15x15" />
      <span className='t-14 link'>{label}</span>
      <HandySvg src={images[`angle.svg`]} className="logo-15x15 angle" style={{ fill: "#2D8EFF" }} />
    </Link>
  );

  return (
    <div className='profile-settings-links-container'>
      <div className='profile-settings-links-item'>
        <LinkItem logo={'user'} label={'Аккаунт'} link={"/settings"} />
        <LinkItem logo={'wallet'} label={'Кошелек'} link={"/wallet"} />
      </div>
      {isAdmin &&
        <div className='profile-settings-links-item'>
          <LinkItem logo={'admin'} label={'Админ-панель'} link={"/admin"} />
        </div>
      }
      <div className='profile-settings-links-item' style={{ color: '#F46C6C' }} onClick={logout}>
        <div className={`profile-settings-link`}>
          <span className='t-14'>Выйти</span>
          <HandySvg src={images[`angle.svg`]} className="logo-15x15 angle currentColor" />
        </div>
      </div>
    </div>
  )
}
