import React from 'react';
import Header from '@components/Header/Header';
import Footer from '@components/Footer/Footer';
import './About.css';

import HelloBlock from './Sections/HelloBlock';
import Service from './Sections/Service';
import News from './Sections/News';
import Reputation from './Sections/Reputation';

export default function About() {

  return (
    <>
      <Header />
      <HelloBlock />
      <Service />
      <News />
      <Reputation />
      <Footer />
    </>
  )
}
