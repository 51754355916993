import React from 'react';
import './Home.css'

import Header from './Sections/Header';
import Reviews from './Sections/Reviews';
import About from './Sections/About';
import HowItWork from './Sections/HowItWork';
import Tariffs from './Sections/Tariffs';
import FAQ from './Sections/FAQ';
import Footer from '@components/Footer/Footer';


const Home = () => {
  return (
    <>
      <Header />
      <Reviews />
      <About />
      <HowItWork />
      <Tariffs />
      <FAQ />
      <Footer />
    </>
  );
}

export default Home;
