import React, { useState, useContext } from 'react'
import {fetchRegistration} from '@components/shared/api';
import InputField from '@components/shared/InputField';
import { AuthContext } from '@context/AuthContext';

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/auth', false, /\.(svg)$/));


export default function RegistrationForm({handleCodeStep}) {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [channel, setChannel] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    
    const { handleLogin } = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !name || !channel || !password || !confirmPassword) {
            setError('Пожалуйста, заполните все поля.');
            return;
        }
        if (password !== confirmPassword) {
            setError('Пароли не совпадают.');
            return;
        }
        try {
          await fetchRegistration(email, password, name, channel);
          // handleLogin();
        } catch (err) {
            console.error(err);
            setError('Ошибка при регистрации.');
        }
      handleCodeStep()
    };

    return (
        <div className="auth-form">
            <p className="text-blue-left">Создайте аккаунт</p>
            <h2>ДОБРО ПОЖАЛОВАТЬ в ToUP</h2>
            <form onSubmit={handleSubmit}>
                <InputField
                    label="Email"
                    type="email"
                    placeholder="Ваша почта"
                    logo={images['mail.svg']}
                    value={email}
                    handleChange={(e) => setEmail(e.target.value)}
                />
                <div className="registration-email-channel-container">
                    <InputField
                        label="Имя"
                        type="text"
                        placeholder="Напишите имя"
                        logo={images['login.svg']}
                        value={name}
                        handleChange={(e) => setName(e.target.value)}
                    />
                    <InputField
                        label="Профиль"
                        type="text"
                        placeholder="Ваш Telegram"
                        logo={images['telegram.svg']}
                        value={channel}
                        handleChange={(e) => setChannel(e.target.value)}
                    />
                </div>
                <InputField
                    label="Пароль"
                    type="password"
                    placeholder="Придумайте пароль"
                    logo={images['password.svg']}
                    value={password}
                    handleChange={(e) => setPassword(e.target.value)}
                />
                <InputField
                    label="Повторите пароль"
                    type="password"
                    placeholder="Повторите пароль"
                    logo={images['password.svg']}
                    value={confirmPassword}
                    handleChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button type="submit">Создать аккаунт</button>
            </form>
            {error && <p className="error">{error}</p>}
            <p className='switch-auth-window'>Уже есть аккаунт? <div onClick={handleLogin}>Войти</div></p>
        </div>
    );
};
