import React, { useState } from 'react';
import { useTable } from 'react-table';
import './OperationsTable.css'; // Подключим CSS для стилей

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/partners/partnership', false, /\.(svg)$/));

const OperationsTable = ({ operations = [], usdToRub }) => {
  // Состояние для отображения всех элементов
  const [showAll, setShowAll] = useState(false);

  // Используем только первые 5 элементов, если showAll = false
  const data = React.useMemo(() => (showAll ? operations : operations.slice(0, 5)), [showAll, operations]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Тип',
        accessor: 'type',
        Cell: ({ row }) => (
          <div className='operation-table-row'>
            <div className={`icon`}>
              <img src={images[`referrals.svg`]} alt={row.original.icon} />
            </div>
            <span className='t-14'>Партнерство</span>
          </div>
        ),
      },
      {
        Header: 'Сумма',
        accessor: 'amount',
        Cell: ({ row }) => (
          <div>
            <div className="usd-amount">{row.original.get_money}</div>
            <div className="rub-amount">{row.original.get_money}</div>
          </div>
        ),
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ row }) => (
          <span className="status status-active">
            <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg" className={`status-active mr-5`}>
              <circle cx="2.5" cy="2.5" r="2.5" />
            </svg>
            Успешно
          </span>
        ),
      },
      {
        Header: 'Активность',
        accessor: 'activity',
        Cell: ({ row }) => (
          <div>
            <div className='t-14' style={{
              whiteSpace: 'nowrap'
            }}>
              Оплата за партнерство
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <div className="operations-table">
      <h2>Операции</h2>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {operations.length > 5 && !showAll && (
        <a href="#" className="show-all" onClick={() => setShowAll(true)}>Показать все</a>
      )}
    </div>
  );
};

export default OperationsTable;
