import React, { useContext, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from '@context/AuthContext';
import { ThemeContext } from '@context/ThemeContext';
import Profile from '@components/Profile/Profile';
import ProfileSettings from '@components/Profile/Options/ProfileSettings';
import Wallet from '@components/Profile/Options/Wallet';
import Tasks from '@components/Tasks/Tasks';
import Home from '@components/Home/Home';
import Support from '@components/Support/Support';
import Partnership from '@components/Partnership/Partnership';
import About from './components/About/About';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const AdminPanel = lazy(() => import('@components/AdminPanel/AdminPanel'));

function App() {
  const { theme } = useContext(ThemeContext);
  const { isAuthenticated, isAdmin } = useContext(AuthContext);

  return (
    <div className={`App ${theme}`}>
      <Routes>
        <Route path="/admin" element={isAdmin ? <AdminPanel /> : <Navigate to="/" />} />
        <Route path="/" element={isAuthenticated ? <Profile /> : <Home />} />
        <Route path="/about" element={!isAuthenticated ? <About /> : <Navigate to="/" />} />
        <Route path="/partnership" element={<Partnership />} />
        <Route path="/settings" element={isAuthenticated ? <ProfileSettings /> : <Navigate to="/" />} />
        <Route path="/wallet" element={isAuthenticated ? <Wallet /> : <Navigate to="/" />} />
        <Route path="/tasks" element={isAuthenticated ? <Tasks /> : <Navigate to="/" />} />
        <Route path="/support" element={isAuthenticated ? <Support /> : <Navigate to="/" />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default function RootApp() {
  return (
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  );
}
