import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Balance from './Partnership/Balance';
import ReferalCode from './Partnership/ReferalCode';
import Statistic from './Partnership/Statistic';
import OperationsTable from './Partnership/OperationsTable';

export default function PartnerInfo({ data }) {
  const [usdToRub, setUsdToRub] = useState(null);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
        setUsdToRub(response.data.rates.RUB);
      } catch (error) {
        console.error('Ошибка при получении курса валют:', error);
      }
    };

    fetchExchangeRate();
  }, []);

  return (
    <>
      <div className="partnership">
        <span className="label">Партнерство</span>
        <div className='partnership-row'>
          <Balance balance={data.balance} usdToRub={usdToRub} />
          <ReferalCode referalCode={data.code} />
        </div>
        <div className='partnership-row'>
          <Statistic />
          <OperationsTable operations={data.earn} usdToRub={usdToRub} />
        </div>
      </div>
    </>
  )
}
