import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '@context/AuthContext';

import Registration from '@components/authentification/Registration';
import Login from '@components/authentification/Login';
import ResetPassword from '@components/authentification/ResetPassword';
import Desktop from './Header.Desktop';
import Mobile from './Header.Mobile';
import './Header.css';

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/header', false, /\.(svg)$/));

const Header = () => {
  const { activeAuthMenu } = useContext(AuthContext);

  return (
    <header className='header'>
      <div className="header-left">
        <Link to={'/'} >
          <HandySvg src={images['ToUP.svg']} style={{ fill: 'var(--text-color)', height: '20px', width: '60px' }} />
        </Link>
      </div>
      <Desktop />
      <Mobile />

      {activeAuthMenu === 'Registration' && <Registration />}
      {activeAuthMenu === 'Login' && <Login />}
      {activeAuthMenu === 'Reset' && <ResetPassword />}
    </header>
  );
};

export default Header;
