import axios from 'axios';

const main_url = 'https://toup.pro/api/api'

export const fetchRegistration = async (email, password, name, channel) => {
  return await axios.post(`${main_url}/reg`, {
    email: email,
    password: password,
    name: name,
    telegram_link: channel
  });
}

export const fetchAuth = async (email, password) => {
  return await axios.post(`${main_url}/auth`, {
    email: email,
    password: password,
  });
}

export const fetchUserInfo = async () => {
  const token = localStorage.getItem('token');
  try {
    return await axios.post(`${main_url}/get_user_info`, {
      token: token,
      value: token,
      field: 'token'
    });
  } catch (error) {
    console.error('Ошибка запроса get_user_info:', error);
  }
  return null;
};

export const fetchTasks = async (isAutoTask, offset, tasksPerPage, searchText = null, activeFilter = null) => {
  const api = isAutoTask ? 'getautotask' : 'gettask'

  const requestData = {
    token: localStorage.getItem('token'),
    offset: offset,
    limit: tasksPerPage,
  };

  if (searchText) {
    requestData.search = searchText;
  }

  if (activeFilter) {
    requestData.filter = activeFilter;
  }

  try {
    return await axios.post(`${main_url}/${api}`, requestData);
  } catch (error) {
    console.error(`Ошибка запроса ${api}:`, error);
  }
  return null;
}

export const addTask = async (taskData, handleBehaviour) => {
  const handleFormatDates = (taskData) => {
    const checkDates = (isAllDay, startTime, endTime, name) => {
      if (!isAllDay && (startTime === '' || endTime === '')) {
        return {status: false, message: `Поля начала и окончания периода в ${name} не должны быть пустыми`}
      } else if (!isAllDay && startTime >= endTime) {
        return {status: false, message: `Время начала работы не может быть больше времени окончания - ${name}`}
      } else {
        return {status: true}
      }
    };

    const formatDateRanges = (isAllDay, startTime, endTime, count, countType, fullName) => {
      const checkResult = checkDates(isAllDay, startTime, endTime, fullName);
      if (checkResult.status) {
        const settings = [];
        if (isAllDay) {
          startTime = '00:00';
          endTime = '23:59';
        }
        if (count === 0) {
          count = taskData.count_actions
          countType = taskData.type_count
        }
        settings.push(startTime, endTime, count, countType);
        return settings;
      }
      return null
    }


    let formattedData = {};
    if (taskData.calendarMode === 'week') {
      taskData.weeklyData.forEach((day) => {
        if (day.isActive) {
          formattedData[day.apiName] = []; // Сделать локальную переменную
          day.times.forEach((time) => {
            let settings = formatDateRanges(time.isAllDay, time.startTime, time.endTime, time.count, time.countType, day.fullName);
            if (settings) {
              formattedData[day.apiName].push(settings);
            }
          });

          // Условие для удаления ключа, если массив пуст
          if (formattedData[day.apiName].length === 0) {
            delete formattedData[day.apiName];
          }
        }
      });
    } else {
      taskData.calendarData.forEach((range) => {
        const formatDate = (date) => {
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = String(date.getFullYear()).slice(-2);
          return `${year}.${month}.${day}`;
        };
        const rangeString = `${formatDate(new Date(range.startDate))}-${formatDate(new Date(range.endDate))}`;
        formattedData[rangeString] = [];

        range.times.forEach((time) => {
          let settings = formatDateRanges(time.isAllDay, time.startTime, time.endTime, time.count, time.countType, rangeString);
          if (settings) {
            formattedData[rangeString].push(settings);
          }
        });

        // Условие для удаления ключа, если массив пуст
        if (formattedData[rangeString].length === 0) {
          delete formattedData[rangeString];
        }
      })
    }

    // Проверка на пустоту объекта formattedData
    if (Object.keys(formattedData).length === 0) {
      return {};
    }
    return {
      Type: taskData.calendarMode,
      settings: formattedData
    };
  };

  const formattedData = {
    token: localStorage.getItem('token'),
    data: [
      {
        task_type: taskData.task_type,
        target_url: taskData.target_url,
        count_actions: parseInt(taskData.count_actions),
        type_count: taskData.type_count,
        task_obj: taskData.task_obj,
        task_time: parseInt(taskData.task_time),
        behavior: handleBehaviour(),
        channel_id: taskData.channel_id,
        spread: taskData.spread,
        time_controll: handleFormatDates(taskData),
      },
    ],
  };

  // taskData.isAutoTask && formattedData['channel_id'] = taskData.channel_id
  // taskData.isAutoTask && formattedData['channel_id'] = taskData.channel_id

  const api = taskData.isAutoTask ? 'addautotask' : 'addtask'
  try {
    return await axios.post(`${main_url}/${api}`, formattedData);
  } catch (error) {
    console.error(`Ошибка запроса ${api}:`, error);
  }
}

export const editTask = async (isAutoTask, taskId, field, value) => {
  const api = isAutoTask ? 'editAutoTask' : 'editTask';
  try {
    return await axios.post(`${main_url}/${api}`, {
      token: localStorage.getItem('token'),
      id: taskId,
      field: field,
      value: value,
    });
  } catch (error) {
    console.error(`Ошибка запроса ${api}:`, error);
  }
}

export const editName = async (newName) => {
  try {
    return await axios.post(`${main_url}/editname`, {
      token: localStorage.getItem('token'),
      name: newName,
    });
  } catch (error) {
    console.error('Ошибка при отправке имени:', error);
  }
}

export const editEmail = async (newEmail) => {
  try {
    return await axios.post(`${main_url}/editmail`, {
      token: localStorage.getItem('token'),
      mail: newEmail,
    });
  } catch (error) {
    console.error('Ошибка при отправке email:', error);
  }
}

export const resetPassword = async (newPassword) => {
  try {
    return await axios.post(`${main_url}/editmail`, {
      token: localStorage.getItem('token'),
      password: newPassword,
    });
  } catch (error) {
    console.error('Ошибка при отправке пароля:', error);
  }
}

export const addChannel = async (newTelegramName, newTelegramLink) => {
  try {
    return await axios.post(`${main_url}/addchannel`, {
      token: localStorage.getItem('token'),
      name: newTelegramName,
      link: newTelegramLink
    });
  } catch (error) {
    console.error('Ошибка при добавлении канала:', error);
  }
}

export const editChannel = async (editingIndex, updatedChannel) => {
  try {
    return await axios.post(`${main_url}/editchannel`, {
      token: localStorage.getItem('token'),
      index: editingIndex,
      name: updatedChannel.name,
      link: updatedChannel.link,
    });
  } catch (error) {
    console.error('Ошибка при отправке email:', error);
  }
}

export const deleteChannel = async (index) => {
  try {
    return await axios.post(`${main_url}/deletechannel`, {
      token: localStorage.getItem('token'),
      index: index,
    });
  } catch (error) {
    console.error('Ошибка при отправке email:', error);
  }
}

export const getPartner = async () => {
  return await axios.post(`${main_url}/getpartner`, {
    token: localStorage.getItem('token'),
  });
}

export const getSession = async () => {
  return await axios.post(`${main_url}/getsession`, {
    token: localStorage.getItem('token'),
  });
}

export const uploadSessions = async (formData) => {
  return await axios.post(`${main_url}/uploadSessions`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}