import React, {useContext} from 'react';
import {TaskContext} from '@components/Tasks/AddTaskWindow/TaskContext';
import { TimeContext } from './TimeContext';

import WeeklyForm from './WeeklyForm';
import CalendarForm from './CalendarForm';

import "react-datepicker/dist/react-datepicker.css";

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

export default function Calendar({ handleCurrentWindow }) {
  const {
    calendarData, setCalendarData,
    weeklyData, setWeeklyData,
    selectedMode, setSelectedMode,
  } = useContext(TimeContext);
  const { taskData, handleTaskDataChange } = useContext(TaskContext);

  const handleSelectedMode = (mode) => {
    setSelectedMode(mode);
  };

  const handleSave = () => {
    handleTaskDataChange('calendarMode', selectedMode);
    handleTaskDataChange('calendarData', calendarData);
    handleTaskDataChange('weeklyData', weeklyData);
    handleCurrentWindow({ window: 'default' });
  };

  const handleReset = () => {
    setSelectedMode(taskData.calendarMode);
    setCalendarData(taskData.calendarData);
    setWeeklyData(taskData.weeklyData);
  }

  const CalendarTitle = () => {
    return (
      <div className='calendar-title-container'>
        <div className="task-window-back-btn" onClick={() => (handleCurrentWindow({ window: 'default' }))}>
          <HandySvg src={images['back.svg']} className={`logo-15x15`} />
        </div>
        <h2>Выберите дату и время</h2>
        <div className='t-14'>Наш сервис работает по часовому поясу UTC+3 (Москва)</div>
        <div className='calendar-title-mode-toggle'>
          <button
            className={`${selectedMode === 'week' ? 'active' : ''} t-14`}
            onClick={() => (handleSelectedMode('week'))}>
            Еженедельно
          </button>
          <button
            className={`${selectedMode === 'date' ? 'active' : ''} t-14`}
            onClick={() => (handleSelectedMode('date'))}>
            По дате
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className='calendar-settings-container'>
      <div className="calendar-container">
        <div className='calendar-item'>
          <CalendarTitle />
          <WeeklyForm isActive={selectedMode === 'week'} />
        </div>
        <div className='calendar-vertical-divider' />
        <div className='calendar-item'>
          <CalendarForm isActive={selectedMode === 'date'} />
        </div>
      </div>
      <div className='calendar-buttons-container'>
        <button className='calendar-default-btn' onClick={handleReset}>Сбросить</button>
        <button className='calendar-save-btn' onClick={handleSave}>Подтвердить</button>
      </div>
    </div>
  );
}
