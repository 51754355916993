import React, { useRef, useEffect, useContext } from 'react';
import { TaskContext } from '@components/Tasks/AddTaskWindow/TaskContext';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import dragDataPlugin from 'chartjs-plugin-dragdata';

Chart.register(dragDataPlugin);

const ChartComponent = ({ chartData, handleChartData }) => {
  const chartRef = useRef(null);
  const { updateBehavior } = useContext(TaskContext);

  // Функция для форматирования даты и времени с переносом строки
  const formatTime = (date) => {
    const year = date.getFullYear().toString().slice(-2); // Последние две цифры года
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`; // Формат: год/месяц/день + перенос строки + часы:минуты
  };

  // Функция для создания массива меток времени на оси x
  const createTimeLabels = () => {
    const currentDate = new Date(); // Текущее время
    const labels = [];
    for (let i = 0; i < chartData.countIntervals; i++) {
      const timeLabel = new Date(currentDate.getTime() + i * chartData.period_time); // Добавляем интервал
      labels.push(formatTime(timeLabel)); // Форматируем и добавляем метку с переносом строки
    }
    return labels;
  };

  const createChartData = () => {
    return {
      labels: createTimeLabels(), // Используем функцию для генерации меток с датой и временем
      datasets: [
        {
          label: 'Bots',
          data: chartData.behavior,
          borderColor: 'rgba(45,142,255,1)',
          borderWidth: 3,
          pointBackgroundColor: 'white',
          pointRadius: 10,
          pointHoverRadius: 10,
          fill: true,
        },
      ],
    };
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, chart.chartArea.bottom);
      gradient.addColorStop(0, 'rgba(45,142,255,1)');
      gradient.addColorStop(1, 'rgba(45,142,255,0)');
      chart.data.datasets[0].backgroundColor = gradient;
      chart.data.datasets[0].data = chartData.behavior;
      chart.data.labels = createTimeLabels(); // Обновляем метки времени с датой и переносом строки
      chart.update();
    }
  }, [chartData.behavior, chartData.countIntervals]);

  const handleDragEnd = (e, datasetIndex, index, value) => {
    const currentData = [...chartRef.current.data.datasets[0].data];
    currentData[index] = value;
    const newBehavior = updateBehavior(currentData, chartData.countIntervals, chartData.count_actions);
    handleChartData('behavior', newBehavior);
    chartRef.current.data.datasets[0].data = newBehavior;

    const maxValue = Math.max(...newBehavior);
    const chartInstance = chartRef.current;
    if (chartInstance) {
      chartInstance.options.scales.y.min = 0;
      chartInstance.options.scales.y.max = maxValue * 1.3;
      chartInstance.update('none');
    }
  };

  return (
    <Line
      ref={chartRef}
      data={createChartData()}
      options={{
        animation: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              callback: function (value) {
                return this.getLabelForValue(value).split('\n'); // Разделение по переносу строки
              }
            }
          },
          y: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: Math.max(...chartData.behavior) * 1.5,
            max: Math.max(...chartData.behavior) * 1.5,
            grid: {
              display: false,
            },
            stepSize: 1,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context) => ` ${context.raw}`,
            },
          },
          dragData: {
            round: 1,
            showTooltip: true,
            onDragEnd: handleDragEnd,
          },
        },
        interaction: {
          mode: 'index',
          intersect: false,
        },
      }}
    />
  );
};

export default ChartComponent;
