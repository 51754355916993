import React, {Component, createRef, useEffect, useState} from 'react';
import {fetchTasks} from '@components/shared/api';
import { debounce } from 'lodash';
import Header from '@components/Header/Header';
import Footer from '@components/Footer/Footer';
import TaskCard from './TasksPageComponents/TaskCard';
import TaskWindow from './AddTaskWindow/TaskWindow';
import { AuthContext } from '@context/AuthContext';
import { TaskProvider } from './AddTaskWindow/TaskContext';
import './Tasks.css';

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

class Tasks extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      isTaskWindowOpen: false,
      isFilterWindowOpen: false,
      isSearchWindowOpen: false,
      isAutoTask: false,
      loading: false,
      activeFilter: null,
      searchText: '',
      windowWidth: 0,

      tasks: [],           // Отображаемые задачи
      regularTasks: [],
      countRegularTasks: 0,   // Обычные задачи
      autoTasks: [],
      countAutoTasks: 0,        // Автозадачи
      taskPerPage: 15,
      pageNumber: 1,
      countPages: 0,
    };
    this.intervalId = null;

    this.searchRef = createRef(); // Создаем ref для окна поиска
    this.searchButtonRef = createRef();  // Создаем реф для кнопки поиска
    this.filterRef = createRef(); // Создаем ref для окна фильтра
    this.filterButtonRef = createRef();  // Создаем реф для кнопки фильтра

    // Дебаунсируемая функция для обработки поиска
    this.handleSearchDebounced = debounce(this.refreshTasks, 300);  // Задержка 300 мс
  }

  handleResize = () => (this.setState({ windowWidth: window.innerWidth }))
  
  handleEnterKey = (event) => {
    if (event.key === 'Enter' && document.activeElement.tagName === 'INPUT') {
      event.preventDefault();
      document.activeElement.blur(); // Снимаем фокус с активного поля
    }
  };

  componentDidMount() {
    this.fetchAllTasks();  // Загружаем оба типа задач при инициализации
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('mousedown', this.handleClickSearchOutside);
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', this.handleEnterKey);
    this.setState({ windowWidth: window.innerWidth });

    // Устанавливаем интервал для обновления задач каждые 10 минут
    this.intervalId = setInterval(() => {
      this.refreshTasks();
    }, 5000);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('mousedown', this.handleClickSearchOutside);
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('keydown', this.handleEnterKey);
    clearInterval(this.intervalId);
  }

  // Запрашиваем все задачи (обычные и автозадачи)
  fetchAllTasks = async () => {
    this.setState({ loading: true });

    try {
      // Запрашиваем обычные задачи
      const regularTasksResponse = await fetchTasks(
        false,
        0,
        this.state.taskPerPage
      );
      const autoTasksResponse = await fetchTasks(
        true,
        0,
        this.state.taskPerPage
      );

      // Проверяем статус и обновляем состояние
      if (regularTasksResponse.data.status === false || autoTasksResponse.data.status === false) {
        this.context.logout();
      } else {
        this.setState({
          regularTasks: regularTasksResponse.data.data,
          countRegularTasks: regularTasksResponse.data.count,
          countPages: Math.ceil(regularTasksResponse.data.count / this.state.taskPerPage),
          autoTasks: autoTasksResponse.data.data,
          countAutoTasks: autoTasksResponse.data.count,
          tasks: regularTasksResponse.data.data, // По умолчанию отображаем обычные задачи
          loading: false,
        });
      }
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
      this.setState({ loading: false });
    }
  };

  // Метод для обновления отображаемого списка задач
  changeTaskType = (newType) => {
    const isAutoTask = newType === 'autotask';
    if (isAutoTask !== this.state.isAutoTask) {
      this.setState({
        isAutoTask: isAutoTask,
        tasks: isAutoTask ? this.state.autoTasks : this.state.regularTasks,
        pageNumber: 1,
        countPages: isAutoTask ?
          Math.ceil(this.state.countAutoTasks / this.state.taskPerPage)
          :
          Math.ceil(this.state.countRegularTasks / this.state.taskPerPage),
      });
    }
  };

  // Обновляем данные для текущего типа задач
  refreshTasks = async (pageNumber = this.state.pageNumber) => {
    try {
      this.setState({
        pageNumber: pageNumber,
      });
      const response = await fetchTasks(
        this.state.isAutoTask,
        (pageNumber - 1) * this.state.taskPerPage,
        this.state.taskPerPage,
        this.state.searchText,
        this.state.activeFilter
      )

      this.setState({
        pageNumber: pageNumber,
        loading: false,
      })
      if (response.data.status === false) {
        this.context.logout();
      } else {
        this.setState({
          tasks: response.data.data,
        });

        this.state.isAutoTask ?
          this.setState({
            autoTasks: response.data.data,
            countAutoTasks: response.data.count,
            countPages: Math.ceil(response.data.count / this.state.taskPerPage),
          })
          :
          this.setState({
            regularTasks: response.data.data,
            countRegularTasks: response.data.count,
            countPages: Math.ceil(response.data.count / this.state.taskPerPage),
          })
      }
    } catch (error) {
      console.error('Ошибка при обновлении данных:', error);
      this.setState({ loading: false });
    }
  };

  handleOpenTaskWindow = () => {
    this.setState({ isTaskWindowOpen: true });
  };

  handleCloseTaskWindow = () => {
    this.setState({ isTaskWindowOpen: false });
    this.refreshTasks(1);
  };

  componentDidUpdate(prevProps, prevState) {
    // Если фильтры или строка поиска изменились, вызываем refreshTasks
    if (
      prevState.activeFilter !== this.state.activeFilter
    ) {
      this.refreshTasks();
    }
  }

  handleFilter = (filter) => {
    this.setState({ activeFilter: this.state.activeFilter === filter ? '' : filter });
  }

  // Кнопка фильтра
  handleClickSearchOutside = (event) => {
    if (this.searchRef.current && !this.searchRef.current.contains(event.target)) {
      this.setState({ isSearchWindowOpen: false }); // Закрываем фильтр, если клик вне его области
    }
  };

  // Обработчик изменений в поле поиска
  handleSearchInputChange = (e) => {
    this.setState({ searchText: e.target.value });
    this.handleSearchDebounced();
  };

  SearchWindow = () => {
    const searchButton = this.searchButtonRef.current;

    if (searchButton) {
      const rect = searchButton.getBoundingClientRect();

      return (
        <div
          ref={this.searchRef}
          className='search-window'
          style={{
            top: `${rect.bottom + window.scrollY + 10}px`
          }}
        >
          <input
            type='text'
            className='search-input'
            placeholder='Поиск задач...'
            value={this.state.searchText}
            onChange={this.handleSearchInputChange}  // Добавляем обработчик с debounce
          />
          <img src={images['search.svg']} alt='search.svg' />
        </div>
      )
    }
  }

  handleOpenSearchWindow = () => (this.setState({ isSearchWindowOpen: !this.isSearchWindowOpen }))

  SearchField = () => {
    return (
      <>
        <div className='search-bar desktop'>
          <input
            type='text'
            className='search-input'
            placeholder='Поиск задач...'
            value={this.state.searchText}
            onChange={this.handleSearchInputChange}  // Добавляем обработчик с debounce
          />
          <img src={images['search.svg']} alt='search.svg' />
        </div>
        <button
          className='search-bar mobile'
          ref={this.searchButtonRef}
          onClick={() => this.handleOpenSearchWindow()}
        >
          <img src={images['search.svg']} alt='search.svg' />
        </button>
        {this.state.isSearchWindowOpen && <this.SearchWindow />}
      </>
    );
  };

  // Кнопка фильтра
  handleClickOutside = (event) => {
    if (this.filterRef.current && !this.filterRef.current.contains(event.target)) {
      this.setState({ isFilterWindowOpen: false }); // Закрываем фильтр, если клик вне его области
    }
  };

  FilterItem = ({ filter, label }) => (
    <div
      className={`filter-item ${this.state.activeFilter === filter ? 'active' : ''}`}
      onClick={() => this.handleFilter(filter)}
    >
      {label}
    </div>
  )

  FilterWindow = () => {
    const filterButton = this.filterButtonRef.current;

    if (filterButton) {
      const rect = filterButton.getBoundingClientRect();
      return (
        <div
          ref={this.filterRef}
          className='filter-window'
          style={{
            top: `${rect.bottom + window.scrollY + 10}px`,
            left: `${rect.left + window.scrollX - (this.state.windowWidth <= 930 && 117)}px`,
          }}
        >
          <this.FilterItem filter='complite_actions' label='По количеству' />
          <this.FilterItem filter='active' label='По статусу' />
          <this.FilterItem filter='date_add' label='По дате' />
          <this.FilterItem filter='task_type' label='По типу' />
        </div>
      );
    }

    return null;
  };

  FilterButton = () => {
    return (
      <>
        <button
          ref={this.filterButtonRef}  // Привязываем реф к кнопке
          onClick={() => this.setState({ isFilterWindowOpen: !this.state.isFilterWindowOpen })}
          className='filter-btn desktop-btn'
        >
          {this.state.windowWidth > 930 ?
            <>
              <span className='mr-5 desktop-text'>Сортировать</span>
              <img src={images['angle.svg']} alt='angle.svg' />
            </>
            :
            <HandySvg src={images['settings.svg']} className='logo-15x15' alt='angle.svg' />
          }
        </button>
        {this.state.isFilterWindowOpen && <this.FilterWindow />}
      </>
    )
  }

  TasksTitle = () => {
    return (
      <div className='tasks-title'>
        <div>
          <h1>{`Все ${this.state.isAutoTask ? 'авто' : ''}задачи`}</h1>
          <div style={{ display: 'flex', gap: '10px' }}>
            <button
              className={`tasks-type ${this.state.isAutoTask ? '' : 'active'}`}
              onClick={() => this.changeTaskType('task')}
            >
              Задачи
            </button>
            <button
              className={`tasks-type ${this.state.isAutoTask ? 'active' : ''}`}
              onClick={() => this.changeTaskType('autotask')}
            >
              Автозадачи
            </button>
          </div>
        </div>
        <div className='tasks-buttons'>
          <this.SearchField />
          <this.FilterButton />
          <button onClick={this.handleOpenTaskWindow} className='square-box'>
            <HandySvg src={images['add.svg']} className='logo-15x15' />
          </button>
        </div>
      </div>
    )
  }

  TasksCards = () => (
    <div className="tasks-grid">
      {this.state.tasks.map(task => (
        <TaskCard key={task.id} task={task} isAutoTask={this.state.isAutoTask} />
      ))}
    </div>
  );

  Pagination = ({activePage, countPages}) => {
    const [startPage, setStartPage] = useState(2);
    const [endPage, setEndPage] = useState(countPages-1);
    const [pages, setPages] = useState([]);

    const handleCountItems = (event) => {
      const countItems = parseInt(event.target.value);
      this.setState({
        taskPerPage: countItems,
        pageNumber: activePage,
        countPages: this.state.isAutoTask ?
          Math.ceil(this.state.countAutoTasks / countItems) :
          Math.ceil(this.state.countRegularTasks / countItems),
      });
      this.refreshTasks(1);
    };

    const handlePageNumber = (pageNumber) => {
      this.refreshTasks(pageNumber);
    };

    const TaskPage = ({ pageNumber }) => (
      <button
        key={pageNumber}
        className={`page ${activePage === pageNumber ? 'active' : ''}`}
        onClick={() => handlePageNumber(pageNumber)}
      >
        {pageNumber}
      </button>
    );

    useEffect(() => {
      setStartPage(Math.max(2, activePage - 1));
      setEndPage(Math.min(countPages-1, activePage + 1))
    }, [activePage, countPages])

    useEffect(() => {
      const newPages = [];
      for (let i = startPage; i <= endPage; i++) {
        newPages.push(<TaskPage key={i} pageNumber={i} />);
      }
      setPages(newPages);
    }, [startPage, endPage])

    return (
      <div className='tasks-pagination'>
        <div className='task-count-container'>
          <select value={this.state.taskPerPage} onChange={handleCountItems}>
            <option value={15}>15 задач</option>
            <option value={25}>25 задач</option>
            <option value={50}>50 задач</option>
            <option value={100}>100 задач</option>
          </select>
        </div>
        <div className='pages-container'>
          <button
            className={`page ${activePage > 1 ? 'active' : ''}`}
            onClick={() => handlePageNumber(activePage > 1 ? activePage - 1 : countPages)}
          >
            <HandySvg src={images['prevPage.svg']} alt="prevPage.svg" className='currentColor logo-15x15' />
          </button>
          <TaskPage pageNumber={1} />
          {startPage >= 3 && <span className='triple-points'>...</span>}
          {pages}
          {endPage <= countPages - 2 && <span className='triple-points'>...</span>}
          {countPages > 1 && <TaskPage pageNumber={countPages} />}
          <button
            className={`page ${activePage < countPages ? 'active' : ''}`}
            onClick={() => handlePageNumber(activePage < countPages ? activePage + 1 : countPages)}
          >
            <HandySvg src={images['nextPage.svg']} alt="nextPage.svg" className='currentColor logo-15x15' />
          </button>
        </div>
      </div>
    );
  }

  LoadingScreen = () => (
    <div className="loading-screen">
      <div className="spinner"></div>
    </div>
  );

  EmptyPage = () => {
    return (<div className='empty-tasks'>
      <img src={images['emptyTasks.svg']} alt='emptyTasks.svg' />
      <div className='label'>Список пуст</div>
      <div className='description'>У вас пока не создано ни одной задачи</div>
      <button onClick={this.handleOpenTaskWindow}>Создать</button>
    </div>)
  }

  render() {
    return (
      <>
        <Header />
        <div className="tasks">
          <this.TasksTitle />

          {this.state.loading ? (
            <this.LoadingScreen/>
          ) : this.state.tasks.length === 0 ? (
            <this.EmptyPage />
          ) : (
            <>
              <this.TasksCards />
              <this.Pagination
                activePage={this.state.pageNumber}
                countPages={this.state.countPages}
              />
            </>
          )}

          {this.state.isTaskWindowOpen && (
            <TaskProvider isAutoTask={this.state.isAutoTask}>
              <TaskWindow handleClose={this.handleCloseTaskWindow} />
            </TaskProvider>
          )}
        </div>
        <Footer />
      </>
    );
  }
}

export default Tasks;
