import React from 'react';
import './Balance.css';

export default function Balance({ balance, usdToRub }) {
  return (
    <div className='partner-balance'>
      <span className='t-14' style={{ 'fontWeight': 400 }}>Баланс реферального кошелька</span>
      <span className='balance'>${balance}</span>
      <span className='t-14 course'>1 USD = {usdToRub ? usdToRub.toFixed(2) : '...'} RUB</span>
      <button className='withdraw'>Вывести</button>
      <span className='help'>Требуется помощь? Напишите нам</span>
    </div>
  )
}
