import React, { useEffect, useContext } from 'react';
import { AuthContext } from '@context/AuthContext';
import LoginForm from './LoginForm';
import LoginInfoBlock from './LoginInfoBlock';
import './Login.css';

const Login = () => {
  const { handleClose } = useContext(AuthContext);
  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!e.target.closest('.auth-window')) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleClose]);

  return (
    <div className="auth-window-overlay">
      <div className="auth-window">
        <div className="auth-content">
          <LoginForm />
          <LoginInfoBlock />
        </div>
      </div>
    </div>
  );
};

export default Login;
