import React, {useEffect, useContext, useState, setState} from 'react';
import { AuthContext } from '@context/AuthContext';
import RegistrationForm from './RegistrationForm';
import RegistrationInfoBlock from './RegistrationInfoBlock';
import './Registration.css';

const Registration = () => {
  const {handleClose, handleLogin} = useContext(AuthContext);
  const [stageStep, setStageStep] = useState("Registration");

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!e.target.closest('.auth-window')) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleClose]);

  const handleCodeStep = () => (
    setStageStep("Code")
  )

  const CodeStage = () => {
    return (
      <div className='auth-form' style={{gap: '5px'}}>
        <p className="text-blue-left">Подтвердите код</p>
        <h2>Мы отправили код на вашу почту</h2>
        <div className='code-field-container'>
          <div className='code-field-item'/>
          <div className='code-field-item'/>
          <div className='code-field-item'/>
          <div className='code-field-item'/>
        </div>
        <span className="text-blue-left">
          Не пришел код?
          <span style={{color: 'var(--text-color)'}}>
            examplelongpost@gmail.com
          </span>
        </span>
        <button onClick={() => handleLogin()}>Отправить код</button>
        <button className='reset-back-btn' onClick={() => setStageStep('Registration')}>Назад</button>
      </div>
    )
  };

  return (
    <div className="auth-window-overlay">
      <div className="auth-window">
        <div className="auth-content">
          {stageStep === "Registration" ? <>
            <RegistrationForm handleCodeStep={handleCodeStep}/>
          </> : <>
            <CodeStage/>
          </>}
          <RegistrationInfoBlock/>
        </div>
      </div>
    </div>
  );
};

export default Registration;
