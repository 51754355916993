import React, { useState } from 'react'
import InputField from '@components/shared/InputField';

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

export default function WorkTime({ day, dayIndex, time, timeIndex, addTimeToRange, removeTimeFromRange, editor }) {
  const [startTime, setStartTime] = useState(time.startTime);
  const [endTime, setEndTime] = useState(time.endTime);
  const [isAllDay, setIsAllDay] = useState(time.isAllDay);
  const [count, setCount] = useState(time.count);
  const [countType, setCountType] = useState(time.countType);

  const handleTimeEdit = (setter, field, value) => {
    setter(value);
    editor(dayIndex, timeIndex, field, value);
  }

  return (
    <div className='execution-time'>
      <div className='execution-time-label'>
        <div className='t-14' style={{ 'fontWeight': 400, 'opacity': 0.8 }}>
          Время выполнения {day}
        </div>
        <img src={images['add.svg']} alt={'add'} className='add-btn' onClick={() => (addTimeToRange(dayIndex))}/>
        {timeIndex !== 0 &&
          <img src={images['delete.svg']} alt={'delete'} className='delete-btn'
               onClick={() => (removeTimeFromRange(dayIndex, timeIndex))}/>
        }
      </div>
      <div className='time-container'>
        <InputField
          type="time"
          placeholder="C 9:00"
          logo={images['clock.svg']}
          value={isAllDay ? '00:00' : startTime}
          handleChange={(e) => handleTimeEdit(setStartTime, 'startTime', e.target.value)}
          disabled={isAllDay}
        />
        <InputField
          type="time"
          placeholder="До 18:00"
          logo={images['clock.svg']}
          value={isAllDay ? '23:59' : endTime}
          handleChange={(e) => handleTimeEdit(setEndTime, 'endTime', e.target.value)}
        />
      </div>
      <span
        style={{ display: 'flex', gap: '5px', cursor: 'pointer' }}
        onClick={(e) => handleTimeEdit(setIsAllDay, 'isAllDay', !isAllDay)}>
        <input
          type="checkbox"
          checked={isAllDay}
        />
        <div style={{ display: 'flex' }}>
          Выполнять весь день
        </div>
      </span>
      <InputField
        label="Кол-во действий" type="image-number-select-input" placeholder="Количество"
        logo={images['todo.svg']} error={false}
        value={count} handleChange={(e) => handleTimeEdit(setCount, 'count', e.target.value)}
        options={{
          minValue: 0, maxValue: 4000,
          includeField: {
            value: countType,
            handleChange: (e) => handleTimeEdit(setCountType, 'countType', e),
            hidden: true,
            withoutEmptyField: true,
            top: '36px',
            left: '129px',
          },
          options: [
            { label: 'Количество', value: 'num' },
            { label: 'Проценты', value: 'perc' },
          ],
        }}
      />
    </div>
  )
}
