import React, { useState, useEffect, useContext, useCallback } from 'react';
import {editName, editEmail, resetPassword, addChannel, editChannel, deleteChannel} from '@components/shared/api';
import { AuthContext } from '@context/AuthContext';
import Header from '@components/Header/Header';
import Footer from '@components/Footer/Footer';
import OptionsSidebar from './OptionsSidebar';
import InputField from '../../shared/InputField';
import './ProfileSettings.css';
import './Options.css';
import './EditPopup.css'; // Создайте CSS для popup-окна

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/profile/options', false, /\.(svg)$/));

// Вынесенные и мемоизированные компоненты
const UserInfo = React.memo(({ username, email, handleUsernameChange, handleEmailChange, saveButton }) => {
  return (
    <div className='profile-settings-info-item'>
      <div className='profile-settings-input-fields'>
        <InputField
          label="Имя"
          type="text"
          placeholder="Ваше имя"
          logo={images['user.svg']}
          value={username}
          handleChange={handleUsernameChange}
        />
        <InputField
          label="Email"
          type="email"
          placeholder="Ваша почта"
          logo={images['mail.svg']}
          value={email}
          handleChange={handleEmailChange}
        />
      </div>
      <button className='add-channel-btn' onClick={saveButton}>Сохранить изменения</button>
    </div>
  );
});

// Компонент для popup-окна редактирования канала
const EditChannelPopup = ({ telegram, onSave, onCancel }) => {
  const [name, setName] = useState(telegram.name);
  const [link, setLink] = useState(telegram.link);

  const handleSave = () => {
    onSave({ name, link });
  };

  return (
    <div className="edit-popup-overlay">
      <div className="edit-popup">
        <h2>Редактирование канала</h2>
        <InputField
          label="Название канала"
          type="text"
          placeholder="Введите новое название"
          logo={images['telegram.svg']}
          value={name}
          handleChange={(e) => setName(e.target.value)}
        />
        <InputField
          label="Ссылка на канал"
          type="text"
          placeholder="Введите новую ссылку"
          logo={images['link.svg']}
          value={link}
          handleChange={(e) => setLink(e.target.value)}
        />
        <div className="edit-popup-buttons">
          <button onClick={onCancel}>Отмена</button>
          <button onClick={handleSave}>Сохранить</button>
        </div>
      </div>
    </div>
  );
};

const TelegramItem = React.memo(({ telegram, index, handleDelete, handleEdit }) => (
  <div className='profile-settings-telegram-item'>
    <div className='telegram-name'>
      <img src={images['telegram.svg']} alt="Telegram Logo" />
      <span>{telegram.name}</span>
    </div>
    <div className='telegram-link'>
      <img src={images['link.svg']} alt="Link Icon" />
      <span>{telegram.link}</span>
    </div>
    <button className='edit-btn desktop' onClick={() => handleEdit(index)}>
      <img src={images['edit.svg']} alt="Edit Icon" />
    </button>
    <button className='edit-btn desktop' onClick={() => handleDelete(index)}>
      <img src={images['delete.svg']} alt="Delete Icon" />
    </button>
    <button className='edit-btn mobile'>
      <img src={images['3points.svg']} alt="More Options Icon" />
    </button>
  </div>
));

const ChannelsList = React.memo(({ telegrams, setTelegrams, newTelegramName, newTelegramLink, handleNameChange, handleLinkChange, addChannel, handleEditClick }) => {
  const handleDeleteChannel = async (index) => {
    try {
      await deleteChannel(index)
      alert('Канал успешно удалён!');
      setTelegrams((prevTelegrams) => prevTelegrams.filter((_, i) => i !== index));
    } catch (error) {
      console.error('Ошибка при удалении канала:', error);
    }
  };

  return (
    <div className='profile-settings-info-item'>
      <div className='profile-settings-input-fields'>
        <InputField
          label="Название канала"
          type="text"
          placeholder="Например Amro"
          logo={images['telegram.svg']}
          value={newTelegramName}
          handleChange={handleNameChange}
        />
        <InputField
          label="Ссылка на канал"
          type="text"
          placeholder="https://t.me/+Hp5DjFnpWXdhMTBi"
          logo={images['link.svg']}
          value={newTelegramLink}
          handleChange={handleLinkChange}
        />
      </div>
      <button className='add-channel-btn' onClick={addChannel}>Добавить канал</button>
      <div className='profile-settings-telegram-container'>
        {Array.isArray(telegrams) && telegrams.map((telegram, index) => (
          <TelegramItem
            key={telegram.link}
            telegram={telegram}
            index={index}
            handleDelete={handleDeleteChannel}
            handleEdit={() => handleEditClick(index)}
          />
        ))}
      </div>
    </div>
  );
});

const PasswordReset = ({
                         password,
                         confirmPassword,
                         handlePasswordChange,
                         handleConfirmPasswordChange,
                         resetPassword
                       }) => (
  <div className='profile-settings-info-item'>
    <div className='profile-settings-input-fields'>
      <InputField
        label="Пароль"
        type="password"
        placeholder="Введите пароль"
        logo={images['password.svg']}
        value={password}
        handleChange={handlePasswordChange}
      />
      <InputField
        label="Повторите пароль"
        type="password"
        placeholder="Повторите пароль"
        logo={images['password.svg']}
        value={confirmPassword}
        handleChange={handleConfirmPasswordChange}
      />
    </div>
    <button className='add-channel-btn' onClick={resetPassword}>Изменить пароль</button>
  </div>
);

export default function ProfileSettings() {
  const { userInfo, handleChangeUserInfo } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [newTelegramName, setNewTelegramName] = useState('');
  const [newTelegramLink, setNewTelegramLink] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [telegrams, setTelegrams] = useState(userInfo.channels || []);
  const [editingIndex, setEditingIndex] = useState(null); // Индекс редактируемого канала

  // Синхронизируем только при первой загрузке данных
  useEffect(() => {
    if (userInfo.name) setUsername(userInfo.name);
    if (userInfo.email) setEmail(userInfo.email);
  }, [userInfo]);

  const saveButton = useCallback(() => {
    if (username !== userInfo.name) {
      handleChangeUserInfo('name', username);
      editName(username);
    }
    if (email !== userInfo.email) {
      handleChangeUserInfo('email', email);
      editEmail(email);
    }
  }, [username, email, userInfo, handleChangeUserInfo]);

  const handleUsernameChange = useCallback((e) => {
    setUsername(e.target.value);
  }, []);

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const handleConfirmPasswordChange = useCallback((e) => {
    setConfirmPassword(e.target.value);
  }, []);

  const handleResetPassword = useCallback(async () => {
    if (password !== confirmPassword) {
      alert('Пароли не совпадают!');
      return;
    }
    try {
      await resetPassword(password);
      alert('Пароль успешно изменен!');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Ошибка при изменении пароля:', error);
    }
  }, [password, confirmPassword]);

  const handleAddChannel = useCallback(async () => {
    if (!newTelegramName || !newTelegramLink) {
      alert('Пожалуйста, заполните все поля!');
      return;
    }
    try {
      await addChannel(newTelegramName, newTelegramLink);
      alert('Канал успешно добавлен!');
      // Обновляем список каналов с добавленным новым каналом
      setTelegrams((prevTelegrams) => [...prevTelegrams, { name: newTelegramName, link: newTelegramLink }]);
      setNewTelegramName('');
      setNewTelegramLink('');
    } catch (error) {
      console.error('Ошибка при добавлении канала:', error);
    }
  }, [newTelegramName, newTelegramLink]);

  const handleSaveEdit = async (updatedChannel) => {
    try {
      await editChannel(editingIndex, updatedChannel);
      setTelegrams((prevTelegrams) =>
        prevTelegrams.map((t, i) => (i === editingIndex ? updatedChannel : t))
      );
      setEditingIndex(null); // Закрываем popup после сохранения
    } catch (error) {
      console.error('Ошибка при редактировании канала:', error);
    }
  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
  };

  return (
    <>
      <Header />
      <div className='profile-settings'>
        <div className='t-28' style={{ marginTop: "35px", marginBottom: "20px" }}>Личная информация</div>
        <div className='profile-setting-container'>
          <OptionsSidebar />
          <div className='profile-settings-info-container'>
            <UserInfo
              username={username}
              email={email}
              handleUsernameChange={handleUsernameChange}
              handleEmailChange={handleEmailChange}
              saveButton={saveButton}
            />
            <ChannelsList
              telegrams={telegrams}
              setTelegrams={setTelegrams}
              newTelegramName={newTelegramName}
              newTelegramLink={newTelegramLink}
              handleNameChange={(e) => setNewTelegramName(e.target.value)}
              handleLinkChange={(e) => setNewTelegramLink(e.target.value)}
              addChannel={handleAddChannel}
              handleEditClick={handleEditClick}
            />
            {editingIndex !== null && (
              <EditChannelPopup
                telegram={telegrams[editingIndex]}
                onSave={handleSaveEdit}
                onCancel={handleCancelEdit}
              />
            )}
            <PasswordReset
              password={password}
              handlePasswordChange={handlePasswordChange}
              handleConfirmPasswordChange={handleConfirmPasswordChange}
              resetPassword={handleResetPassword}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
