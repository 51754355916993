import React, { useState } from 'react';
import './FAQ.css';

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/home', false, /\.(svg)$/));

export default function FAQ() {
  const questions = [
    { question: 'Как пополнить кошелек?', answer: 'Пополнение осуществляется следующим образом:' },
    { question: 'Как пополнить кошелек?', answer: 'Пополнение осуществляется следующим образом:' },
    { question: 'Как пополнить кошелек?', answer: 'Пополнение осуществляется следующим образом:' },
    { question: 'Как пополнить кошелек?', answer: 'Пополнение осуществляется следующим образом:' },
    { question: 'Как пополнить кошелек?', answer: 'Пополнение осуществляется следующим образом:' },
    { question: 'Как пополнить кошелек?', answer: 'Пополнение осуществляется следующим образом:' },
  ]

  const FaQCard = ({ question, index }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = (e) => {
      setIsOpen(!isOpen);
    }

    return (
      <div className='faq-item' key={index} onClick={handleOpen}>
        <div className='faq-question'>
          <div className='faq-question-leftside'>
            <div className='faq-question-logo'>
              <HandySvg src={images[`wallet.svg`]} />
            </div>
            {question.question}
          </div>
          <div className='faq-question-rightside'>
            <HandySvg src={images[`angle.svg`]} />
          </div>
        </div>
        {isOpen && (
          <>
            <div className='faq-answer'>{question.answer}</div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className='faq'>
      <div className='home-chapter-box'>ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</div>
      <div className='faq-label'>Мы ответим на все ваши вопросы</div>
      <div className='faq-items'>
        {questions.map((question, index) => (
          <FaQCard key={index} question={question} index={index} />
        ))}
      </div>
      <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} className='faq-button'>
        Задать вопрос
      </a>
    </div>
  );
};