import React from 'react';
import './Transactions.css';

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/profile/options', false, /\.(svg)$/));

const transactionsData = [];

export default function Transactions() {
  return (
    <div className="transactions-block">
      <h2>Транзакции</h2>
      <table className="transactions-table">
        <thead>
          <tr>
            <th>Тип</th>
            <th>Сумма</th>
            <th>Метод оплаты</th>
            <th>Статус</th>
            <th>Активность</th>
            <th>Дата</th>
          </tr>
        </thead>
        <tbody>
          {transactionsData.map((transaction, index) => (
            <tr key={index}>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className='mr-5 circle-25x25'>
                    <HandySvg src={images[`${transaction.type}.svg`]} className='logo-15x15 currentColor' />
                  </div>
                  <span className='t-14'>{transaction.type === 'waste' ? 'Оплата' : 'Пополнение'}</span>
                </div>
              </td>
              <td>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{transaction.amountUSD} USD</span>
                  <span style={{ opacity: 0.5 }}>{transaction.amountRUB} RUB</span>
                </div>
              </td>
              <td>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{transaction.paymentMethod}</span>
                  <span style={{ opacity: 0.5 }}>{transaction.paymentCard}</span>
                </div>
              </td>
              <td>
                <span className="status status-green" style={{ height: '26px' }}>
                  <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg" className={`status-active mr-5`}>
                    <circle cx="2.5" cy="2.5" r="2.5" />
                  </svg>
                  Активен
                </span>
              </td>
              <td>{transaction.activity}</td>
              <td>{transaction.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
