import React from 'react';
import './Reviews.css';

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/home', false, /\.(svg)$/));
const avatars = ImageUtils.importAllImages(require.context('@assets/home/avatars', false, /\.(png)$/));

export default function Reviews() {
  const msg = "“Идейные соображения высшего порядка, а также консультация с широким активом позволяет оценить значение системы обучения кадров”";
  const reviews = [
    { author: "Сорокин Кирилл", post: "СММ менеджер", avatar: "1.png" },
    { author: "Сорокин Кирилл", post: "СММ менеджер", avatar: "1.png" },
    { author: "Сорокин Кирилл", post: "СММ менеджер", avatar: "1.png" },
    { author: "Сорокин Кирилл", post: "СММ менеджер", avatar: "1.png" },
  ];

  return (
    <div className='reviews'>
      {reviews.map((review, index) => {
        return (
          <div className='review-item' key={index}>
            <div >
              <img src={images[`quotes.svg`]} alt="quotes" />
            </div>
            <span className='review-text'>{msg}</span>
            <div className='review-user'>
              <img src={avatars[review.avatar]} className='review-user-avatar' alt="avatar" />
              <div className='review-user-text'>
                <span className='review-username'>{review.author}</span>
                <span className='review-user-post'>{review.post}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};