import React from 'react'

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/auth', false, /\.(svg)$/));

export default function LoginInfoBlock() {
  return (
    <div className="blue-rectangle">
      <p className="overlay-text">Поднимите уровень своего канала с нами</p>
      <div className="login-info-block-container">
        <div className="login-info-block block1">
          <img src={images['check.svg']} alt="logo" />
          <span className="login-info-block-title">Более 5000 пользователей</span>
        </div>
        <div className="login-info-block block2">
          <img src={images['community.svg']} alt="logo" />
          <span className="login-info-block-title">Охват широкой аудитории</span>
          <span className="login-info-block-description">Наш сервис охватывает огромное количество тематических</span>
        </div>
        <div className="login-info-block block3">
          <img src={images['trophy.svg']} alt="logo" />
          <span className="login-info-block-title">N1 в сфере накрутки</span>
        </div>
        <div className="login-info-block block4">
          <img src={images['check.svg']} alt="logo" />
          <span className="login-info-block-title">Бонусы для новых пользователей</span>
        </div>
      </div>
    </div>
  )
}
