import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '@context/AuthContext';
import { ThemeContext } from '@context/ThemeContext';
import Notification from './Notification';

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/header', false, /\.(svg)$/));

export default function Desktop() {
  const { toggleTheme } = useContext(ThemeContext);
  const { isAuthenticated, userInfo, logout, handleLogin, handleRegistration } = useContext(AuthContext);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const location = useLocation();

  const toggleMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
    setIsNotificationOpen(false);
  };

  const toggleNotification = () => {
    setIsNotificationOpen(!isNotificationOpen);
    setIsProfileMenuOpen(false);
  };

  const handleMouseDown = (e) => {
    const userWindow = e.target.closest('.user-dropdown-menu');
    const notificationWindow = e.target.closest('.notification-dropdown-menu');

    if (!userWindow) 
      setIsProfileMenuOpen(false);
      
    if (!notificationWindow)
      setIsNotificationOpen(false);
  };

  // Используем useEffect вместо методов жизненного цикла
  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const getLinkClass = (path) => {
    return location.pathname === path ? 'header-link active' : 'header-link';
  };

  const AuthenticatedLinks = () => (
    <>
      <Link to="/profile" className={getLinkClass('/')}>
        <HandySvg src={images['profile.svg']} className="header-icon mr-5" />
        Личный кабинет
      </Link>
      <Link to="/tasks" className={getLinkClass('/tasks')}>
        <HandySvg src={images['clock.svg']} className="header-icon mr-5" />
        Управление задачами
      </Link>
      <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" className={getLinkClass('/support')}>
        <HandySvg src={images['support.svg']} className="header-icon mr-5" />
        Поддержка
      </a>
      <Link to="/partnership" className={getLinkClass('/partnership')}>
        <HandySvg src={images['handshake.svg']} className="header-icon mr-5" />
        Партнерство
      </Link>
    </>
  );

  const GuestLinks = () => (
    <>
      <Link to="/" className={getLinkClass('/')}>
        Главная
      </Link>
      <Link to="/about" className={getLinkClass('/about')}>
        О нас
      </Link>
      <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" className="header-link">
        Поддержка
      </a>
      <Link to="/partnership" className={getLinkClass('/partnership')}>
        Партнерство
      </Link>
    </>
  );

  const HeaderCenter = () => {
    return (
      <div className="header-center">
        {isAuthenticated ? <AuthenticatedLinks getLinkClass={getLinkClass} /> : <GuestLinks />}
      </div>
    );
  };

  const ProfileMenu = () => (
    <div className="user-dropdown-menu t-14">
      <div className="profile-section">
        <img src={images['userProfile.svg']} alt="Profile" className="profile-icon" />
        <span className="profile-name">{userInfo.name}</span>
        <img src={images['lightTheme.svg']} alt="logo" className='menu-theme-toggle-desktop' onClick={toggleTheme} />
      </div>
      <Link to={'/settings'} className="user-dropdown-menu-item">
        <img src={images['settings.svg']} alt="logo" />
        Настройки
      </Link>
      <Link to={'/wallet'} className="user-dropdown-menu-item">
        <img src={images['myWallet.svg']} alt="logo" />
        Мой счет
      </Link>
      <button className="header-button logout-button t-14" onClick={logout}>Выйти</button>
    </div>
  );

  const AuthenticatedRight = () => (
    <>
      <Link to={'/wallet'} style={{ textDecoration: 'none' }}>
        <button className="header-button narrow-button">
          <img src={images['wallet.svg']} alt="logo" className='mr-5' />
          {userInfo.balance} $
        </button>
      </Link>
      <button className="header-button hiding-button" onClick={toggleNotification} >
        <img src={images['notification.svg']} alt="logo" />
      </button>
      <button className="header-button hiding-button" onClick={toggleMenu}>
        <img src={images['userProfile.svg']} alt="logo" />
      </button>
      {isProfileMenuOpen && <ProfileMenu />}
      {isNotificationOpen && <Notification />}
    </>
  );

  const GuestRight = () => (
    <>
      <button className="hiding-button theme-toggle" onClick={toggleTheme}>
        <img src={images['lightTheme.svg']} alt="logo" className='mr-5' />
      </button>
      <button className="header-button" onClick={handleLogin}>Войти</button>
      <button className="header-button register-button" onClick={handleRegistration}>Регистрация</button>
    </>
  );

  const HeaderRight = () => {
    return (
      <nav className="header-right">
        {isAuthenticated ? (
          <AuthenticatedRight />
        ) : (
          <GuestRight />
        )}
      </nav>
    );
  };

  return (
    <>
      <HeaderCenter />
      <HeaderRight />
    </>
  );
}
