import React, { useEffect, useContext } from 'react';
import { TaskContext } from '@components/Tasks/AddTaskWindow/TaskContext';
import InputField from '../../shared/InputField';
import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

export default function TimeField() {
  const { taskData, handleTaskDataChange } = useContext(TaskContext);
  
  const timeUnit = {
    'hours': 60 * 60,
    'days': 24 * 60 * 60,
    'weeks': 7 * 24 * 60 * 60,
  };

  const time_unit_labeles = {
    'hours': 'Часы',
    'days': 'Дни',
    'weeks': 'Недели',
  }

  useEffect(() => {
    const newTaskTime = taskData.time * timeUnit[taskData.timeUnit];
    if (taskData.task_time !== newTaskTime) {
      handleTaskDataChange('task_time', newTaskTime);
    }
  }, [taskData.time, taskData.timeUnit]);

  const handleTimeChange = (e) => {
    const value = e.target.value;
    if (value * timeUnit[taskData.timeUnit] <= 180 * timeUnit['days']) {
      handleTaskDataChange('time', value);
    }
  };

  const handleTimeUnitChange = (value) => {
    handleTaskDataChange('timeUnit', value);
  };


  return (
    <InputField
      label="Время на выполнение" type="number-select-input" placeholder="Выберите..."
      logo={images['todo.svg']} error={taskData.errors.time}
      value={taskData.time} handleChange={handleTimeChange}
      options={{
        includeField: {
          value: time_unit_labeles[taskData.timeUnit],
          handleChange: handleTimeUnitChange,
        },
        options: [
          { label: 'Часы', value: 'hours' },
          { label: 'Дни', value: 'days' },
          { label: 'Недели', value: 'weeks' },
        ],
      }}
    />
  );
}
