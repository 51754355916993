import React, { useState, useRef, useEffect, useContext } from 'react';
import { AuthContext } from '@context/AuthContext';
import { TaskContext } from '@components/Tasks/AddTaskWindow/TaskContext';
import InputField from '@components/shared/InputField';
import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const reactionsList = [
  '👍', '👎', '❤️', '🔥', '🥰', '👏', '😁', '🤔', '🤯', '😱', '🤬', '😢', '🎉', '🤩', '🤮', '💩', '🙏',
  '👌', '🕊', '🤡', '🥱', '🥴', '😍', '🐳', '❤️‍🔥', '🌚', '🌭', '💯', '🤣', '⚡️', '🍌', '🏆', '💔', '🤨',
  '😐', '🍓', '🍾', '💋', '🖕', '😈', '😴', '😭', '🤓', '👻', '👨‍💻', '👀', '🎃', '🙈', '😇', '😨', '🤝',
  '✍️', '🤗', '🎅', '🎄', '☃️', '💅', '🤪', '🗿', '🆒', '💘', '🙉', '🦄', '😘', '💊', '🙊', '😎', '👾',
  '🤷‍♀️', '🤷', '🤷‍♂️', '😡'
];

export default function Reactions() {
  const { userInfo } = useContext(AuthContext);
  const { taskData, handleTaskDataChange } = useContext(TaskContext);
  const [showReactions, setShowReactions] = useState(false);
  const popupRef = useRef(null);

  const toggleReaction = (reaction) => {
    let newReactions;
    if (taskData.task_obj.includes(reaction)) {
      newReactions = taskData.task_obj.filter(r => r !== reaction);
    } else if (taskData.task_obj.length < 9) {
      newReactions = [...taskData.task_obj, reaction];
    } else {
      return;
    }
    handleTaskDataChange('task_obj', newReactions);
  };

  // Обработчик для клика вне popup
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowReactions(false); // Закрываем окно реакций при клике вне его
    }
  };

  useEffect(() => {
    if (showReactions) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showReactions]); // Следим за изменением showReactions

  return (
    <>
      <div className='task-form-data'>
        <div className='task-form-data-row'>
          {
            taskData.isAutoTask && <InputField
              label="Разброс кол-ва" type="number" placeholder="%"
              logo={images['percentage.svg']} error={taskData.errors.spread}
              value={taskData.spread || ''}
              handleChange={(e) => handleTaskDataChange('spread', e.target.value)}
              options={{ minValue: 0, maxValue: 100 }}
            />
          }

          <InputField
            label="Кол-во реакций" type="image-number-select-input" placeholder="Реакции"
            logo={images['users-alt.svg']} error={taskData.errors.count_actions}
            value={taskData.count_actions || ''} handleChange={(e) => handleTaskDataChange('count_actions', e.target.value)}
            options={{
              minValue: 0,
              maxValue: taskData.type_count === "num" ?
                (userInfo.bot_count === null ? 0 : userInfo.bot_count)
                : 100,
              includeField: {
                value: taskData.type_count,
                handleChange: (e) => handleTaskDataChange('type_count', e),
                hidden: true,
                withoutEmptyField: true,
                top: '393px',
                left: '200px',
              },
              options: [
                { label: 'Количество', value: 'num' },
                { label: 'Проценты', value: 'perc' },
              ],
            }}
          />
        </div>
        <div className='task-form-data-row'>
          <div className='input-field'>
            <label>Реакции</label>
            <div className="input-container" onClick={() => setShowReactions(!showReactions)}>
              <img src={images['emoji.svg']} alt="logo" className='mr-5' />
              <div className="divider"></div>
              Реакции: {taskData.task_obj.length}
            </div>
            {showReactions && (
              <div className="reactions-popup" ref={popupRef}>
                {reactionsList.map((reaction, index) => (
                  <div
                    key={index}
                    className={`reaction-item ${taskData.task_obj.includes(reaction) ? 'selected' : ''}`}
                    onClick={() => toggleReaction(reaction)}
                  >
                    {reaction}
                    {taskData.task_obj.includes(reaction) && <div className="reaction-selected"></div>}
                  </div>
                ))}
              </div>
            )}
          </div>
          <InputField
            label="Ссылка на пост" type="text" placeholder="Укажите ссылку на пост"
            logo={images['link.svg']} error={taskData.errors.link}
            value={taskData.target_url || ''}
            handleChange={(e) => handleTaskDataChange('target_url', e.target.value)}
          />
        </div>
      </div>
    </>
  );
}