import React, {createContext, useState, useContext} from 'react';
import {TaskContext} from '@components/Tasks/AddTaskWindow/TaskContext';

export const TimeContext = createContext();

export const TimeProvider = ({ children }) => {
  const {taskData} = useContext(TaskContext);
  const [selectedMode, setSelectedMode] = useState(taskData.calendarMode);
  const [weeklyData, setWeeklyData] = useState(taskData.weeklyData);
  const [calendarData, setCalendarData] = useState(taskData.calendarData);

  return (
    <TimeContext.Provider value={{
      calendarData, setCalendarData,
      weeklyData, setWeeklyData,
      selectedMode, setSelectedMode
    }}>
      {children}
    </TimeContext.Provider>
  );
};
