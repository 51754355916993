import React from 'react';
import HelloBlock from './BecomePartners/HelloBlock';
import Advantages from './BecomePartners/Advantages';
import HowItWork from './BecomePartners/HowItWork';
import FAQ from '@components/Home/Sections/FAQ';
import './BecomePartners.css';

export default function BecomePartners() {
  return (
    <>
      <HelloBlock />
      <Advantages />
      <HowItWork />
      <FAQ />
    </>
  )
}
