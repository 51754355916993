import React, {createContext, useState, useEffect, useCallback, useMemo} from 'react';
import {fetchUserInfo} from '@components/shared/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [activeAuthMenu, setActiveAuthMenu] = useState('');

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
    setUserInfo({});
    setIsAdmin(false);
  };

  // Функция для изменения данных пользователя
  const handleChangeUserInfo = async (field, value) => {
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [field]: value,
    }));
  };

  const handleFetchUserInfo = async (token) => {
    const response = await fetchUserInfo();
    if (response.data?.status) {
      setUserInfo(response.data);
      if (response.data.permission) {
        setIsAdmin(response.data.permission === 'admin');
      }
    } else {
      logout();
    }
  };

  useEffect(() => {

    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsAuthenticated(true);
        handleFetchUserInfo(token);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const login = () => {
    setIsAuthenticated(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleRegistration = () => {
    setActiveAuthMenu('Registration');
  }

  const handleLogin = () => {
    setActiveAuthMenu('Login');
  };

  const handleReset = () => {
    setActiveAuthMenu('Reset');
  };

  const handleClose = () => {
    setActiveAuthMenu('');
  };

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      isAdmin,
      userInfo,
      handleChangeUserInfo,
      login,
      logout,
      activeAuthMenu,
      handleRegistration,
      handleLogin,
      handleReset,
      handleClose,
    }}>
      {children}
    </AuthContext.Provider>
  );
};
