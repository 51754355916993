import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './InputField.css';

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));


const InputField = ({
  label, type, placeholder, logo, error, value, handleChange,
  options = {
    minValue: null,
    maxValue: null,
    includeField: {
      value: null,
      handleChange: null,
    },
  },
  handles = {
    handleBlur: null,
  },
  isChangeable = false,
  disabled = false,
}) => {
  const uniqueId = React.useMemo(() => uuidv4(), []);
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [localValue, setLocalValue] = useState(value);
  const [localIncludedValue, setLocalIncludedValue] = useState(options.includeField?.value || "");
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(0);
  const popupRef = useRef(null);

  const handleLocalNumberValueChange = (e) => {
    if ('0' <= e.nativeEvent.data && e.nativeEvent.data <= '9')
      setLocalValue(e.target.value);
  }

  const handleNumberChange = (e) => {
    // debugger;
    let value = parseInt(e.target.value);
    if (isNaN(value) || !Number.isInteger(value))
      value = 0;

    if (options.maxValue != null && value >= options.maxValue)
      e.target.value = options.maxValue;
    else if (options.minValue && value <= options.minValue)
      e.target.value = options.minValue;
    else
      e.target.value = value;
    setLocalValue(e.target.value);
    handleChange(e);
  };

  const handleSelect = (index) => {
    setActiveMenu(index);
    setIsSelectOpen(false);
    setLocalValue(options.options[index].label);
    handleChange(options.options[index].value);
  }

  const handleIncludedSelect = (index) => {
    setActiveMenu(index);
    setIsSelectOpen(false);
    setLocalIncludedValue(options.options[index].label);
    options.includeField.handleChange(options.options[index].value);
  }

  const selectField = () => {
    return (
      <div className='select-field'>
        <div className='selector' onClick={() => setIsSelectOpen(true)}>
          {localValue}
          <img src={images[`angle.svg`]} alt={'angle'}/>
        </div>
        {isSelectOpen && (
          <div className='options' ref={popupRef}>
            {options.options.map((option, index) => (
              <>
                <div key={option.value} className='option' onClick={() => handleSelect(index)}>
                  {option.label}
                </div>
                {index < options.options.length - 1 && <div className="divider" />}
              </>
            ))}
          </div>
        )}
      </div>
    );
  };

  const littleSelectField = () => {
    return (
      <div className='select-field' style={{'width': '50%'}}>
        <div className='selector' onClick={() => setIsSelectOpen(true)}>
          {localIncludedValue}
          <img src={images[`angle.svg`]} alt={'angle'}/>
        </div>
        {isSelectOpen && (
          <div className='options' ref={popupRef} style={{top: '-151px'}}>
            {options.options.map((option, index) => (
              <>
                <div key={option.value} className='option' onClick={() => handleIncludedSelect(index)}>
                  {option.label}
                </div>
                {index < options.options.length - 1 && <div className="divider" />}
              </>
            ))}
          </div>
        )}
      </div>
    );
  };

  // Обработчик для клика вне popup
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setTimeout(() => {
        setIsSelectOpen(false);
      }, 5); // Задержка в 100 миллисекунд
    }
  };

  useEffect(() => {
    if (isSelectOpen) {
      document.addEventListener('mouseup', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [isSelectOpen]);

  const imageSelectField = () => {
    return (
      <>
        <img
          src={images[`${options.includeField.value}.svg`]}
          alt={`${options.includeField.value}.svg`}
          onClick={(e) => {
            e.stopPropagation();
            setIsSelectOpen(true);
          }}
          style={{ cursor: 'pointer' }}
        />
        {isSelectOpen && (
          <div
            className="type-count-selection"
            style={{ top: options.includeField.top, left: options.includeField.left }}
            ref={popupRef}
          >
            {options.options.map((option, index) => (
              <React.Fragment key={option.value}>
                <div
                  className="type-count-option"
                  onClick={() => handleIncludedSelect(index)}
                >
                  <span>{option.label}</span>
                  <img src={images[`${option.value}.svg`]} alt={`${option.value}.svg`}/>
                </div>
                {index < options.options.length - 1 && <div className="divider" />}
              </React.Fragment>
            ))}

          </div>
        )}
      </>
    );
  };

  const textField = (type) => {
    return (<input
      type={type}
      id={uniqueId}
      name={uniqueId}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      disabled={isDisabled}
    />);
  };

  const numberField = () => {
    return (<input
      type='text'
      id={uniqueId}
      name={uniqueId}
      placeholder={placeholder}
      value={localValue}
      onChange={handleLocalNumberValueChange}
      onBlur={handleNumberChange}
      disabled={isDisabled}
    />);
  };

  const selectInputField = () => {
    return (
      <>
        {textField("text", handleChange)}
        {littleSelectField()}
      </>
    );
  };

  const numberSelectInputField = () => {
    return (
      <>
        {numberField()}
        {littleSelectField()}
      </>
    );
  };

  const imageNumberSelectInputField = () => {
    return (
      <>
        {numberField()}
        {imageSelectField()}
      </>
    );
  };

  const textareaField = () => {
    return (<textarea
      id={uniqueId}
      name={uniqueId}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      className='big-text-field'
    />);
  };

  const timeField = () => {
    return (<input
      type='time'
      id={uniqueId}
      name={uniqueId}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
    />);
  };

  const renderInput = () => {
    switch (type) {
      case 'select':
        return (selectField());
      case 'select-input':
        return (selectInputField());
      case 'number-select-input':
        return (numberSelectInputField());
      case 'image-number-select-input':
        return (imageNumberSelectInputField());
      case 'number':
        return (numberField());
      case 'textarea':
        return (textareaField());
      case 'time':
        return (timeField());
      default:
        return (textField(type, handleChange));
    }
  };

  return (
    <div className={`input-field`}>
      {label &&
        <div style={{ display: 'flex' }}>
          <label htmlFor={uniqueId}>{label}</label>
          {isChangeable && <div className='change-btn'>Изменить</div>}
        </div>
      }

      <div className={`input-container ${error ? 'error' : ''}`}>
        {logo && <HandySvg src={logo} className={`logo-15x15 ${error ? 'error' : ''} mr-5`} />}
        <div className="input-field-divider"></div>
        {renderInput()}
      </div>
    </div>
  );
};

export default InputField;
