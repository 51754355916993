import React from 'react'

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/auth', false, /\.(svg)$/));


const InfoItem = ({ logo, text, leftArrow = null, rightArrow = null, isMargin = false }) => (
  <div className={`registration-info-content ${leftArrow ? 'mr-0' : 'ml-0'}`}>
    {leftArrow}
    <div className={`registration-info-block ${isMargin ? 'mb-0' : ''}`}>
      <img src={logo} alt="logo" className="registration-info-logo" />
      {text}
    </div>
    {rightArrow}
  </div>
);

export default function RegistrationInfoBlock() {
  return (
    <div className="blue-rectangle p-3">
      <p className="overlay-text mb-4">Увеличьте свою аудиторию с нами</p>
      <div className="container registration-info-block-container">
        <InfoItem logo={images['registrationInfo.svg']} text="Регистрация"
          leftArrow={<img src={images['arrow1.svg']} alt="logo" className="registration-arrow1-icon" />} />
        <InfoItem logo={images['subscribersInfo.svg']} text="Выбор кол-ва подписчиков"
          rightArrow={<img src={images['arrow2.svg']} alt="logo" className="registration-arrow2-icon" />} />
        <InfoItem logo={images['wallet.svg']} text="Пополнить счет"
          leftArrow={<img src={images['arrow3.svg']} alt="logo" className="registration-arrow3-icon" />} />
        <InfoItem logo={images['task.svg']} text="Добавить задачу" isMargin={true} />
      </div>
    </div>
  )
}
