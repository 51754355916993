import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '@context/AuthContext';
import axios from 'axios';
import Header from '@components/Header/Header';
import TicketWindow from './TicketWindow';
import SupportDialogsMenu from './SupportDialogsMenu';
import SupportDialog from './SupportDialog';
import './Support.css';

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/support', false, /\.(svg)$/));

export default function Support() {
  const { logout, userInfo } = useContext(AuthContext);
  const [isTicketWindowOpen, setIsTicketWindowOpen] = useState(false);
  const [activeDialog, setActiveDialog] = useState(null);
  const [listDialogs, setListDialogs] = useState([]);

  const fetchData = async () => {
    const token = localStorage.getItem('token');
    const url = `http://147.45.186.38:8000/api/getrequests`;
    try {
      const response = await axios.post(url, {
        token: token,
        offset: 0,
      });
      if (response.data) {
        if (response.data.status) {
          setListDialogs(response.data.data);
        } else {
          if (response.data.message === "Token not found") {
            logout();
          }
        }
      }
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  useEffect(() => {
    // Первоначальная загрузка данных
    fetchData();

    // Устанавливаем интервал для обновления данных каждые 10 секунд
    const interval = setInterval(() => {
      fetchData();
    }, 10000); // 10 000 миллисекунд = 10 секунд

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(interval);
  }, []);

  // Добавляем обработчик для клавиши Esc
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setActiveDialog(null);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleOpenTicketWindow = () => {
    setIsTicketWindowOpen(true);
  };

  const handleCloseTicketWindow = () => {
    setIsTicketWindowOpen(false);
    fetchData();
  };

  const handleActiveDialog = (index) => {
    setActiveDialog(index);
  };

  const sendMessage = async (message) => {
    const newMessage = {
      sender: userInfo.id, // или используйте идентификатор пользователя
      message: message,
      date: Math.floor(Date.now() / 1000), // Текущая временная метка в секундах
    };

    // Обновляем список сообщений локально
    const updatedDialogs = [...listDialogs];
    updatedDialogs[activeDialog].messages = [...updatedDialogs[activeDialog].messages, newMessage];
    setListDialogs(updatedDialogs);

    const token = localStorage.getItem('token');
    const url = `http://147.45.186.38:8000/api/send_message_support`;
    try {
      const response = await axios.post(url, {
        token: token,
        request_id: listDialogs[activeDialog].id,
        message: message,
      });
      if (response.data) {
        // Обработчик
      }
    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
    }
  };

  const submitMessage = (message) => {
    sendMessage(message);
  };

  const EmptySupport = () => (
    <div className='empty-support'>
      <img src={images['empty.svg']} alt="empty-support" />
      <div className='label'>Список пуст</div>
      <div className='description'>У вас пока не создано ни одной записи</div>
      <button className='create-ticket-button' onClick={handleOpenTicketWindow}>Создать</button>
    </div>
  );

  const DefaultSupport = () => (
    <div className='support'>
      <div className='support-label'>
        <div className='text'>Все тикеты</div>
        <button className='create-ticket-button' onClick={handleOpenTicketWindow}>Создать тикет</button>
      </div>
      <div className='support-content'>
        <SupportDialogsMenu activeDialog={activeDialog} handleActiveDialog={handleActiveDialog} listDialogs={listDialogs} />
        <SupportDialog dialog={listDialogs[activeDialog]} submitMessage={submitMessage} />
      </div>
    </div>
  );

  return (
    <>
      <Header />
      {listDialogs?.length === 0 ? (
        <EmptySupport />
      ) : (
        <DefaultSupport />
      )}
      {isTicketWindowOpen && <TicketWindow handleClose={handleCloseTicketWindow} />}
    </>
  );
}
