import React, { useState, useContext, useRef, useEffect } from 'react';
import { AuthContext } from '@context/AuthContext';

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/support', false, /\.(svg)$/));

export default function SupportDialog({ dialog, submitMessage }) {
  const { userInfo } = useContext(AuthContext);
  const messagesEndRef = useRef(null);

  const statuses = {
    'wait': { label: 'Ожидание', classname: 'status-yellow' },
    'open': { label: 'Открыт', classname: 'status-green' },
    'close': { label: 'Закрыт', classname: 'status-red' },
  };

  const DialogLabel = () => (
    <div className='label-content'>
      <div className='text'>{dialog?.title}</div>
      <div className='id'>{`#${dialog?.id}`}</div>
      <div className={statuses[dialog?.status]?.classname}>
        <svg className='mr-5' width="5" height="5" viewBox="0 0 5 5" xmlns="http://www.w3.org/2000/svg">
          <circle cx="2.5" cy="2.5" r="2.5" />
        </svg>
        {statuses[dialog?.status]?.label}
      </div>
    </div>
  );

  const formatDate = (timestamp) => {
    const months = [
      'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
      'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
    ];

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = months[date.getMonth()];

    return `${day} ${month}`;
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (dialog?.messages) {
      scrollToBottom();
    }
  }, []);

  const Question = ({ message }) => (
    <div className='question-window'>
      <div className='question-top'>
        <div className='user'>Вы</div>
        <div className='date'>{formatDate(message.date * 1000)}</div>
      </div>
      <div className='question-type'>
        <HandySvg src={images['typeTicket.svg']} className='logo-15x15' />
        {dialog?.type_problem}
      </div>
      <div className='question-text'>{message.message}</div>
    </div>
  );

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
  };

  const Message = ({ message }) => (
    <div className={`message-container ${message.sender === userInfo.id ? 'user' : 'support'}`}>
      <div className='message-logo'>
        <HandySvg src={images[`${message.sender === userInfo.id ? 'user' : 'support'}.svg`]} className='logo-15x15' />
      </div>
      <div className='message-main'>
        <div className='label'>
          <div className='user-name t-14'>{message.sender_name}</div>
          <div className='time'>{formatTime(message.date * 1000)}</div>
        </div>
        <div className='message-block'>
          <div className='text'>{message.message}</div>
        </div>
      </div>
    </div>
  );

  const MessagesWindow = () => (
    <div className='support-dialog-window'>
      {dialog?.messages?.length > 0 && (
        <>
          <Question message={dialog.messages[0]} />
          {dialog.messages.slice(1).map((message, index) => (
            <Message key={index} message={message} />
          ))}
        </>
      )}
      <div ref={messagesEndRef} /> {/* Элемент для прокрутки вниз */}
    </div>
  );

  const MessageInputField = () => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
      event.target.style.height = 'auto';
      event.target.style.height = `${event.target.scrollHeight}px`;
    };

    const handleSubmitMessage = () => {
      if (inputValue.trim()) {
        submitMessage(inputValue);
        setInputValue(''); // Очистка поля ввода
        scrollToBottom();  // Прокрутка вниз
      }
    };

    return (
      <div className='message-input-container'>
        <textarea
          value={inputValue}
          onChange={handleInputChange}
          className='message-input'
          placeholder='Напишите сообщение...'
          rows={1}
          style={{ resize: 'none' }}
        />
        <button>
          <HandySvg src={images[`paperClip.svg`]} className='logo-15x15' />
        </button>
        <button onClick={handleSubmitMessage}>
          <HandySvg src={images[`paperPlane.svg`]} className='logo-15x15' />
        </button>
      </div>
    );
  };

  return (
    <div className='support-messages'>
      {dialog ? (
        <>
          <div>
            <DialogLabel />
            <MessagesWindow />
          </div>
          <MessageInputField />
        </>
      ) : (
        <div className='select-chat'>Выберите или создайте чат, чтобы начать общение</div>
      )}
    </div>
  );
}
