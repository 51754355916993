import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-divider"></div>
      <div className="footer-links">
        <div className="footer-column t-14">
          <h4>Сэкономьте с нами свое время и деньги!</h4>
          <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <div className="footer-button">Написать нам</div>
          </a>
        </div>
        <div className="footer-column">
          <h4>Навигация</h4>
          <ul>
            <li>
              <Link to={'/'}>
                Личный кабинет
              </Link>
            </li>
            <li>
              <Link to={'/tasks'}>
                Управление задачами
              </Link>
            </li>
            <li>
              <Link to={'/partnership'}>
                Партнерство
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Соц. Сети</h4>
          <ul>
            <li>Telegram</li>
            <li>Instagram</li>
            <li>Discord</li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Контакты</h4>
          <p>+7 (911) 000 00 00 с 9:00 до 18:00 пн-пт</p>
          <p>admin@toup.com</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
