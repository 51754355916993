import React, { useState } from 'react';
import './ReferalCode.css';
import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/partners/partnership', false, /\.(svg)$/));

export default function ReferalCode({ referalCode }) {
  const [isCopied, setIsCopied] = useState(false);

  const StepConstructor = ({ number, label, description }) => (
    <div className='step'>
      <div className='step-number'>{number}</div>
      <div>
        <div className='step-label'>{label}</div>
        <div className='step-description'>{description}</div>
      </div>
    </div>
  );

  const handleCopy = () => {
    navigator.clipboard.writeText(referalCode)
      .then(() => {
        setIsCopied(true);  // Показываем уведомление
        setTimeout(() => setIsCopied(false), 2000);  // Скрываем через 2 секунды
      })
      .catch(err => {
        console.error('Ошибка при копировании кода: ', err);
      });
  };

  return (
    <div className='partner-code'>
      <span className='t-14' style={{ fontWeight: 400 }}>Условия партнерской программы</span>
      <div className='steps-container'>
        <StepConstructor
          number='1'
          label='Поделитесь кодом'
          description='Скопируйте и отправьте друзьям код своей реферальной программы.'
        />
        <StepConstructor
          number='2'
          label='Активация кода'
          description='Ваши друзья должны активировать ваш код при пополнении.'
        />
        <StepConstructor
          number='3'
          label='Получите награду'
          description='Вы получите проценты от первого пополнения и бонусы за количество рефералов.'
        />
      </div>
      <div className='code-container'>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
          <span className='t-14' style={{ fontWeight: 400, opacity: 0.5 }}>Ваш реферальный код</span>
          <span className='t-14' style={{ color: '#2D8EFF' }}>{referalCode}</span>
        </div>
        <div style={{ position: 'relative', marginLeft: 'auto' }}>
          <button className='copy-btn' onClick={handleCopy}>Скопировать</button>
          {isCopied && (
            <div className='copy-tooltip'>
              Код скопирован!
            </div>
          )}
        </div>
        {/* <button className='share-btn'>
          <img src={images['share.svg']} alt="Поделиться" />
        </button> */}
      </div>
    </div>
  );
}
