import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios';
import { AuthContext } from '@context/AuthContext';
import Header from '@components/Header/Header';
import Footer from '@components/Footer/Footer';
import OptionsSidebar from './OptionsSidebar';
import Transactions from './Wallet/Transactions';
import './Wallet.css';
import './Options.css';

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/profile/options', false, /\.(svg)$/));

export default function Wallet() {
  const [usdToRub, setUsdToRub] = useState(null);
  const { userInfo } = useContext(AuthContext);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
        setUsdToRub(response.data.rates.RUB);
      } catch (error) {
        console.error('Ошибка при получении курса валют:', error);
      }
    };

    fetchExchangeRate();
  }, []);

  const Subscribe = ({ }) => (
    <div className='wallet-subscribe-block'>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <span className='t-14' style={{ fontWeight: 400, color: 'white' }}>Подписка</span>
        <span className="status-green" style={{ height: '26px', backgroundColor: '#EFFBF2' }}>
          <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg" className={`status-active mr-5`} style={{ backgroundColor: '#EFFBF2' }}>
            <circle cx="2.5" cy="2.5" r="2.5" />
          </svg>
          Активен
        </span>
      </div>
      <div className='wallet-subscribe-container'>
        <div className='t-40' style={{ color: 'white' }}>Пользовательский</div>
        <div className='buttons-container'>
        <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" className='extend-btn t-14'>Продлить подписку</a>
        <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" className='change-btn t-14'>Сменить подписку</a>
        </div>
      </div>
      <div className='wallet-subscribe-container t-14' style={{ fontWeight: 400, color: 'white' }}>
        <span>Действует до 6 сентября 2024</span>
        <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
          <span className='mr-5'>Подробности подписки</span>
          <HandySvg src={images['angle.svg']} className='logo-15x15' style={{ fill: 'white', transform: 'rotate(90deg)' }} />
        </div>
      </div>
    </div>
  );

  const Balance = ({ label, balance, course, buttonText, descriptionBlack, descriptionBlue }) => (
    <div className='wallet-balance-block'>
      <span className='t-14' style={{ fontWeight: 400, opacity: 0.8 }}>{label}</span>
      <div className='wallet-balance-content'>
        <div className='wallet-balance-container'>
          <span className='t-40'>{balance}</span>
          <span className='t-14' style={{ fontWeight: 400 }}>{course}</span>
        </div>
        <div className='wallet-balance-container'>
          <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" className='button-link'>{buttonText}</a>
          <span className='t-14 mr-5'>{descriptionBlack}
          <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" style={{ color: '#2D8EFF', marginLeft: '5px' }}>{descriptionBlue}</a>
          </span>
        </div>
      </div>
    </div>
  );

  const WalletContainer = ({ }) => (
    <div className='wallet-settings-container'>
      <Subscribe />
      <div className='wallet-settings-user-balance'>
        <Balance
          label='Баланс'
          balance={`$${userInfo.balance}`}
          course={`1 USD = ${usdToRub} RUB`}
          buttonText='Пополнить счет'
          descriptionBlack='Требуется помощь?'
          descriptionBlue='Напишите нам'
        />
        <Balance
          label='Ботов'
          balance={`${userInfo.bot_count} шт.`}
          course='1 BOT = $0.25'
          buttonText='Купить еще'
          descriptionBlack=''
          descriptionBlue='Для чего нужны боты?'
        />
      </div>
      <Transactions />
    </div>
  );

  return (
    <>
      <Header />
      <div className='wallet-settings'>
        <div className='t-28' style={{ 'margin-top': "35px", 'margin-bottom': "20px" }}>Кошелек</div>
        <div className='wallet-settings-content'>
          <OptionsSidebar />
          <WalletContainer />
        </div>
      </div>
      <Footer />
    </>
  )
}
