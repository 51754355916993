import React, {useState, useEffect, useRef, useCallback} from 'react';
import {editTask} from '@components/shared/api';
import TelegramPost from './TelegramPost';
import './TaskCard.css';
import { Tooltip } from 'react-tooltip';

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const TaskCard = ({task, isAutoTask}) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [taskTime, setTaskTime] = useState('');
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('В прогрессе');
  const [date, setDate] = useState('');
  const [isActive, setIsActive] = useState(task.active);
  const [isDeleted, setIsDeleted] = useState(task.is_remove);
  const [isExceptedProgress, setIsExceptedProgress] = useState(false);
  const editMenuRef = useRef(null); // ref для меню редактирования
  const progressRef = useRef(null); // ref для меню редактирования


  const getTaskTime = (time) => {
    const msPerMinute = 60;
    const msPerHour = 60 * msPerMinute;
    const msPerDay = 24 * msPerHour;
    const msPerWeek = 7 * msPerDay;

    const weeks = Math.floor(time / msPerWeek);
    const days = Math.floor((time % msPerWeek) / msPerDay);
    const hours = Math.floor((time % msPerDay) / msPerHour);
    const minutes = Math.floor((time % msPerHour) / msPerMinute);

    if (weeks > 0) {
      return `${weeks} ${weeks === 1 ? 'неделя' : 'недели'}`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? 'день' : 'дня'}`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? 'час' : 'часов'}`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? 'минута' : 'минут'}`;
    } else {
      return "менее минуты";
    }
  };

  const getDate = useCallback(() => {
    const date = new Date(task.date_add * 1000);
    const months = [
      'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
      'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
    ];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    return `${day} ${month}, ${year}`;
  }, [task.date_add]); // Зависимость от task.date_add

  useEffect(() => {
    setProgress(Math.floor(task.complite_actions / task.count_actions * 100));
    setStatus(progress >= 100 ? 'Завершено' : 'В прогрессе');
    setDate(getDate());
    setTaskTime(getTaskTime(task.task_time));
  }, [task.complite_actions, task.count_actions, task.task_time, progress, getDate]);

  const typeLabel = {
    'subs': 'Подписчиков',
    'react': 'Реакций',
    'views': 'Просмотров',
  };

  // Обработчик кликов по документу
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editMenuRef.current && !editMenuRef.current.contains(event.target)) {
        setIsEditOpen(false); // Закрываем меню при клике вне его
      }
      if (progressRef.current && !progressRef.current.contains(event.target)) {
        setIsExceptedProgress(false); // Закрываем меню при клике вне его
      }
    };

    // Добавляем слушатель кликов по документу
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Удаляем слушатель при размонтировании компонента
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [progressRef, editMenuRef]);

  const handleEditToggle = () => {
    setIsEditOpen(!isEditOpen);
  }

  const handleStopTask = async () => {
    const response = await editTask(
      isAutoTask,
      task.id,
      'active',
      Math.abs(task.active - 1),
    )

    if (response.data.status) {
      setIsActive(!isActive);
    }
  };

  const handleDeleteTask = async () => {
    const response = await editTask(
      isAutoTask,
      task.id,
      'is_remove',
      1,
    )

    if (response.data.status) {
      setIsDeleted(true);
    }
  };

  const EditMenu = () => (
    <div ref={editMenuRef} className='task-card-edit-menu'> {/* Привязываем ref к меню */}
      {!isAutoTask && progress < 100 &&
        <div className='task-card-edit-menu-item' onClick={handleStopTask}>
          {isActive ? <>
            <span className='mr-5'>Остановить</span>
            <HandySvg src={images[`stopTask.svg`]} className='logo-15x15'/>
          </> : <>
            <span className='mr-5'>Запустить</span>
            <HandySvg src={images[`startTask.svg`]} className='logo-15x15'/>
          </>
          }
        </div>
      }
      <div className='task-card-edit-menu-item' onClick={handleDeleteTask}>
        <span className='mr-5'>Удалить</span>
        <HandySvg src={images[`trash.svg`]} className='logo-15x15' />
      </div>
    </div>
  );

  const extractPostId = (url) => {
    const regex = /t\.me\/(?:c\/)?[^/]+\/(\d+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const [showPost, setShowPost] = useState(false);

  const CardHeader = () => (
    <div className="task-card-header">
      <div className='upper'>
        <HandySvg src={images[`menuDots.svg`]} className='logo-15x15 edit-menu-btn' onClick={handleEditToggle} />
        {isEditOpen && <EditMenu />}
        <div className="task-due-date">
          <img src={images[`calendarClock.svg`]} alt="logo" />
          {taskTime}
        </div>
      </div>
      <div className="task-title">
        <div className='task-type-logo'>
          <HandySvg src={images[`${task.task_type}.svg`]} className="logo-20x20 currentColor" />
        </div>
        {task.count_actions}<br />
        {typeLabel[task.task_type]}
      </div>
      <div className="task-id" onClick={() => setShowPost(true)}>
        <HandySvg src={images[`note.svg`]} className="logo-15x15 currentColor" />
        {extractPostId(task.target_url)}
        <HandySvg src={images[`info.svg`]} className="logo-15x15" />
      </div>
      {showPost && !isAutoTask && task.task_type !== 'subs' &&
        <TelegramPost post={task.post_info_tg} setShowPost={setShowPost}/>
      }
    </div>
  );

  const StatusProgress = () => (
    <div className="status-progress-row">
      <div className="task-status">
        {status}
      </div>
      <div className="progress-info">
        {Math.min(100, progress)}%
        {progress >= 100 ?
          <img src={images['success.svg']} alt="logo" />
          :
          <>
            <div
              data-tooltip-id={`info-tooltip-${task.id}`}
              data-tooltip-class-name={`info-tooltip`}
              data-tooltip-place="top-end"
              className='ml-5'
              style={{ height: '15px', display: 'flex' }}
            >
              <HandySvg src={images[`info.svg`]} className="logo-15x15" />
            </div>
            <Tooltip id={`info-tooltip-${task.id}`} className='task-card-remain-time'>
              На выполнение осталось {getTaskTime((task.date_add + task.task_time) - parseInt(Date.now() / 1000))}
            </Tooltip>
          </>
        }
      </div>
    </div>
  );

  const ProgressBar = () => (
    <div className="task-progress">
      <div className="task-progress-bar">
        <div
          className={`progress-line ${progress >= 100 ? 'success' : ''}`}
          style={{width: `${Math.min(100, progress)}%`}}>
        </div>
      </div>
    </div>
  );

  const ExceptedProgress = () => {
    const currentPeriodIndex = () => {
      const time = Date.now();
      for (let i = 0; i < task.behavior.length; i++) {
        if (task.behavior[i][0] >= time) {
          return i - 1;
        }
      }
      return -1;
    }

    const sumArrayUntilIndex = (arr, index) => {
      let ans = 0
      for (let i = 0; i < index; i++) {
        ans += arr[i][1]
      }
      return ans;
    }

    const exceptedChanges = (index) => {
      if (index >= 0) {
        const timeDiff = Date.now() - task.behavior[index][0]
        const periodTimeDiff = task.behavior[index + 1][0] - task.behavior[index][0]
        const countDiff = task.behavior[index + 1][1]
        return countDiff * timeDiff / periodTimeDiff;
      }
      return 0;
    }

    const index = currentPeriodIndex()
    let exceptedCount = task.count_actions
    // debugger
    if (index >= 0) {
      console.log(sumArrayUntilIndex(task.behavior, index))
      exceptedCount = Math.min(
        sumArrayUntilIndex(task.behavior, index) + exceptedChanges(index),
        task.count_actions);
    } else if (index < 0 && Date.now() < task.behavior[0][0]) {
      exceptedCount = 0
    }

    console.log(index, Date.now(), task.behavior)
    console.log(Math.floor(exceptedCount))

    return (
      <div ref={progressRef} className="task-excepted">
        <div className='my-row'>
          <div className='time'>
            {new Date().toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            })}
          </div>
          <img src={images['loading.svg']} alt="loading" className={'logo-15x15'}/>
        </div>
        <div>Ожидаемое количество: {Math.floor(exceptedCount)}</div>
      </div>
    )
  }

  const TaskStatistic = () => {
    return (
      <div className="task-statistics">
        <div>{Math.min(task.count_actions, task.complite_actions)} из {task.count_actions}</div>
        <div onClick={() => setIsExceptedProgress(true)} className='show-progress'>Показать прогресс</div>
        {isExceptedProgress && <ExceptedProgress/>}
      </div>
    )
  };

  const CardBody = () => (
    <div className="task-card-body">
      <StatusProgress />
      <ProgressBar />
      <TaskStatistic />
    </div>
  );

  const getRemainingTime = (time) => {
    const msPerMinute = 60;
    const msPerHour = 60 * msPerMinute;
    const msPerDay = 24 * msPerHour;
    const msPerWeek = 7 * msPerDay;

    const weeks = Math.floor(time / msPerWeek);
    const days = Math.floor((time % msPerWeek) / msPerDay);
    const hours = Math.floor((time % msPerDay) / msPerHour);
    const minutes = Math.floor((time % msPerHour) / msPerMinute);

    if (weeks > 0) {
      return `${weeks} нед. ${days} дн.`;
    } else if (days > 0) {
      return `${days} дн. ${hours} ч.`;
    } else if (hours > 0) {
      return `${hours} ч. ${minutes} м.`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? 'минута' : 'минут'}`;
    } else {
      return "0 минут";
    }
  };

  const getLastUpdate = () => {
    const date = new Date(task.date_add * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Добавляем ведущий 0 для минут
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const extractChannelId = (url) => {
    const regex = /t\.me\/(?:c\/)?([^/]+)\/\d+/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const AdditionalStatistic = ({ image, textField }) => (
    <div className='additional-statistic'>
      <HandySvg src={image} className="logo-15x15" />
      <span className="text">{textField}</span>
    </div>
  )

  const CardFooter = () => (
    <div className="task-card-footer">
      <hr className="divider" />
      <div className="additional-statistics">
        <AdditionalStatistic image={images['calendar.svg']} textField={date} />
        <AdditionalStatistic image={images['id.svg']} textField={task.id} />
        <AdditionalStatistic image={images['link.svg']} textField={task.target_url} />
        <AdditionalStatistic image={images['hourglass.svg']} textField={getRemainingTime((task.date_add + task.task_time) - parseInt(Date.now() / 1000))} />
        <AdditionalStatistic image={images['todo.svg']} textField={getLastUpdate()}/>
        <AdditionalStatistic image={images['telegram.svg']} textField={task.channel_id ? task.channel_id : extractChannelId(task.target_url)} />
      </div>
    </div>
  );

  return (
    !isDeleted &&
    <div className={`task-card ${!isActive ? 'unactive' : ''}`}>
      <CardHeader />
      {!isAutoTask && <CardBody/>}
      <CardFooter />
    </div >
  );
}

export default TaskCard;
