import React, { useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import './Statistic.css';

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/partners/partnership', false, /\.(svg)$/));

export default function Statistic({ countReferals }) {
  const chartRef = useRef(null);
  const [data] = useState({
    labels: ['4 недели назад', '3 недели назад', '2 недели назад', '1 неделю назад'],
    datasets: [
      {
        label: 'Рефералы',
        data: [12, 19, 3, 5],
        borderColor: '#2D8EFF',
        backgroundColor: 'rgba(45, 142, 255, 0.2)',
        fill: true,
        tension: 0.4,
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Отключаем отображение легенды
      },
    },
  };

  return (
    <div className='partner-statistic'>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <span className='label'>Статистика</span>
          <span className='range'>За последний месяц</span>
        </div>
        <div className='referals-statistic'>
          <div className='circle-30x30'>
            <HandySvg src={images[`users.svg`]} className="logo-15x15 currentColor" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <span style={{ fontSize: '12px', lineHeight: '15.22px', fontWeight: 400 }}>Рефералов</span>
            <div style={{ display: 'flex', gap: '5px' }}>
              <span className='count-referals'>{countReferals}</span>
              <span className='difference-referals'>0</span>
            </div>
          </div>
          <HandySvg src={images[`users.svg`]} className="background-img" />
        </div>
      </div>

      <div style={{ marginTop: '20px', height: '400px' }}>
        <Line ref={chartRef} data={data} options={options} />
      </div>
    </div>
  );
}
