import React, {useRef, useEffect} from 'react';
import './TelegramPost.css';

const TelegramPost = ({ post, setShowPost }) => {
  const postRef = useRef(null); // Ссылка на элемент поста

  // Обработчик кликов по документу для закрытия поста
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (postRef.current && !postRef.current.contains(event.target)) {
        setShowPost(false); // Закрываем пост при клике вне его
      }
    };

    // Добавляем слушатель кликов по документу
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Удаляем слушатель при размонтировании компонента
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [postRef, setShowPost]);

  const getDate = () => {
    const date = new Date(post.time * 1000);
    const months = [
      'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
      'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
    ];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    return `${day} ${month}, ${year}`;
  };

  const getTime = () => {
    const date = new Date(post.time * 1000);
    const hours = date.getHours().toString().padStart(2, '0'); // Часы
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Минуты
    return `${hours}:${minutes}`;
  };

  return (
    <>
      {post ?
        <div className="telegram-post" ref={postRef}>
          <div className="post-header">
            <div>
              <div className="post-author">{post.author || 'Канал Telegram'}</div>
              <div className="post-date">{getDate()}</div>
            </div>
          </div>
          <div className="post-content">
            {post.content || 'Текст отсутствует'}
          </div>
          <div className="post-reactions">
            {post?.reactions.map((reaction) => (
              <div key={reaction[0]} className="reaction">
                <span className='reaction-icon'>{reaction[0]}</span>
                <span className="reaction-count">{reaction[1]}</span>
              </div>
            ))}
          </div>
          <div className="post-footer">
            <div className="post-view-count">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5C7.2 5 3.07 8.11 1.05 12C3.07 15.89 7.2 19 12 19C16.8 19 20.93 15.89 22.95 12C20.93 8.11 16.8 5 12 5ZM12 17C8.13 17 4.92 14.37 3.23 12C4.92 9.63 8.13 7 12 7C15.87 7 19.08 9.63 20.77 12C19.08 14.37 15.87 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9ZM12 13C11.45 13 11 12.55 11 12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12C13 12.55 12.55 13 12 13Z" fill="var(--telegram-post-icon-color)" />
              </svg>
              {post.views || '0 просмотров'}
            </div>
            <div className="post-timestamp">{getTime()}</div>
          </div>
        </div>
        :
        <div className="telegram-post loading" ref={postRef}>
          <span>Пост в процессе загрузки...</span>
          <span>Обновленная информация появится после первого выполнения</span>
        </div>
      }
    </>
  );
};

export default TelegramPost;
