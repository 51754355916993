import React from 'react'
import './Tariffs.css'

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/home', false, /\.(svg)$/));

export default function Tariffs() {
  const tariffsCards = [
    {
      type: 'default',
      label: 'Базовый',
      price: 55,
      description: 'Высокоэффективный для вашего личного пользования',
      items: [
        'Более 5 000 ботов',
        'До 10 автозадач',
        'До 30 задач',
        'До 3 каналов Telegram',
        'Аналитика по раскрутке',
      ]
    },
    {
      type: 'biggest',
      label: 'Профессиональный',
      price: 115,
      description: 'Высокоэффективный для вашего личного пользования',
      items: [
        'Более 12 000 ботов',
        'До 25 автозадач',
        'До 50 задач',
        'До 8 каналов Telegram',
        'Аналитика по раскрутке',
        'Уникальные предложения',
      ]
    },
    {
      type: 'default',
      label: 'Базовый',
      price: 55,
      description: 'Высокоэффективный для вашего личного пользования',
      items: [
        'Более 5 000 ботов',
        'До 10 автозадач',
        'До 30 задач',
        'До 3 каналов Telegram',
        'Аналитика по раскрутке',
      ]
    },
  ]

  const TariffCard = ({ tariffCard }) => {
    return (
      <div className={`tariff-card ${tariffCard.type}`}>
        <div className='tariff-card-type'>{tariffCard.label}</div>
        <div className='tariff-card-price'>
          ${tariffCard.price}
          <span>/месяц</span>
        </div>
        <div className='tariff-card-description'>{tariffCard.description}</div>
        <a
          href="https://t.me/toupSupport_bot"
          target="_blank"
          rel="noopener noreferrer"
          className="tariff-card-buy-button"
        >
          Подключить подписку
        </a>
        {tariffCard.items.map((item, index) => (
          <div key={index} className='task-card-advantage-item'>
            <HandySvg src={images[`success.svg`]} className='mr-5' />
            {item}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='tariffs-background'>
      <div className='tariffs'>
        <div className='home-chapter-box'>ТАРИФЫ</div>
        <div className='label'>Получите бонус за первую подписку</div>
        <div className='tariffs-cards'>
          {tariffsCards.map((tariffCard, index) => (
            <TariffCard key={index} tariffCard={tariffCard} />
          ))}
        </div>
      </div>
    </div>
  );
};