import React, { useContext } from 'react';
import { AuthContext } from '@context/AuthContext';
import { TaskContext } from '@components/Tasks/AddTaskWindow/TaskContext';
import InputField from '@components/shared/InputField';
import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

export default function Subscribers() {
  const { userInfo } = useContext(AuthContext);
  const { taskData, handleTaskDataChange } = useContext(TaskContext);

  return (
    <>
      <div className='task-form-data'>
        <div className='task-form-data-row'>
          {
            taskData.isAutoTask && <InputField
              label="Разброс кол-ва" type="number" placeholder="%"
              logo={images['percentage.svg']} error={taskData.errors.spread}
              value={taskData.spread || ''}
              handleChange={(e) => handleTaskDataChange('spread', e.target.value)}
              options={{ minValue: 0, maxValue: 100 }}
            />
          }
          <InputField
            label="Кол-во подписчиков" type="image-number-select-input" placeholder="Подписчики"
            logo={images['users-alt.svg']} error={taskData.errors.count_actions}
            value={taskData.count_actions || ''}
            handleChange={(e) => handleTaskDataChange('count_actions', e.target.value)}
            options={{
              minValue: 0,
              maxValue: taskData.type_count === "num" ?
                (userInfo.bot_count === null ? 0 : userInfo.bot_count)
                : 100,
              includeField: {
                value: taskData.type_count,
                handleChange: (e) => handleTaskDataChange('type_count', e),
                hidden: true,
                withoutEmptyField: true,
                top: '393px',
                left: '200px',
              },
              options: [
                { label: 'Количество', value: 'num' },
                { label: 'Проценты', value: 'perc' },
              ],
            }}
          />
        </div>
        <div className='task-form-data-row'>
          <InputField
            label="ID канала" type="text" placeholder="Ваш ID канала"
            logo={images['hastag.svg']} error={taskData.errors.channel_id}
            value={taskData.channel_id || ''}
            handleChange={(e) => handleTaskDataChange('channel_id', e.target.value)}
          />
          <InputField
            label="Ссылка на канал" type="text" placeholder="Укажите ссылку на канал"
            logo={images['link.svg']} error={taskData.errors.link}
            value={taskData.target_url || ''}
            handleChange={(e) => handleTaskDataChange('target_url', e.target.value)}
          />
        </div>
      </div>
    </>
  );
}
