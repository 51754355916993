import React, { useState, useEffect } from 'react';
import axios from 'axios';
import InputField from '@components/shared/InputField';
import DropzoneField from '@components/shared/DropzoneField';
import './TicketWindow.css';

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/support', false, /\.(svg)$/));

export default function TicketWindow({ handleClose }) {
  const [label, setLabel] = useState('');
  const [type, setType] = useState('');
  const [text, setText] = useState('');
  const [files, setFiles] = useState([]);

  const submitData = async () => {
    const token = localStorage.getItem('token');
    const url = `http://147.45.186.38:8000/api/createrequest`;
    try {
      const response = await axios.post(url, {
        token: token,
        theme: label,
        type_problem: type,
        message: text,
      });
      if (response.data) {

      }
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  const createButton = () => {
    submitData();
    handleClose();
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (e.target.closest('.ticket-window') === null) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClose]);

  return (
    <div className='ticket-window-overlay'>
      <div className='ticket-window'>
        <div className='ticket-window-label'>Создайте новый тикет</div>
        <div className='ticket-window-description'>Мы постараемся ответить вам в ближайшее время</div>
        <div className='ticket-window-container'>
          <div className='ticket-window-divider' />
          <InputField
            label="Тема вопроса" type="text" placeholder="Тема вопроса"
            logo={images['ticket.svg']} error={false}
            value={label} handleChange={(e) => setLabel(e.target.value)}
          />
          <InputField
            label="Тип проблемы" type="select" placeholder="Выберите..."
            logo={images['typeTicket.svg']} error={false}
            value={type} handleChange={(e) => setType(e.target.value)}
            options={{
              options: [
                { label: 'Накрутка реакций', value: 'Накрутка реакций' },
                { label: 'Накрутка просмотров', value: 'Накрутка просмотров' },
                { label: 'Накрутка подписчиков', value: 'Накрутка подписчиков' },
                { label: 'Обнаружена ошибка', value: 'Обнаружена ошибка' },
                { label: 'Другое', value: 'Другое' },
              ],
            }}
          />
          <InputField
            label="Текст сообщения" type="textarea" placeholder="Тема вопроса"
            logo={images['message.svg']} error={false}
            value={text} handleChange={(e) => setText(e.target.value)}
          />
          <DropzoneField
            files={files}
            setFiles={(files) => setFiles(files)}
            text="прокси (*.txt)"
            allowedExtensions={['txt']}
          />
          <button className='create-ticket-button' onClick={createButton}>Создать тикет</button>
        </div>
      </div>
    </div>
  );
}
